import React, { Component } from "react";
import { connect } from "react-redux";
import { createContact } from "../../actions/contact.action";
import swal from "sweetalert2";
import { dgTag } from "dg-utils";
import { DgHelmet } from "dg-utils";

import Header from "../header/header.component";
import Enquiryform from "./enquiryform.component";
import Contactmap from "./contactmap.component";
import Footervideo from "../footervideo/footervideo.component.js";
import TrackVisibility from "react-on-screen";
import VimeoModal from "../modals/vimeoModal/vimeoModal";

import seojson from "../../assets/files/seojson.js";

class Contact extends Component {
    constructor(props) {
        super(props);

        this.onEnquirySubmit = this.onEnquirySubmit.bind(this);
    }

    onEnquirySubmit(data) {
        const enquiry = {
            your_details: {
                name: data.name,
                email: data.email,
                subject: data.subject,
                message: data.message,
            },
        };
        this.props
            .createContact(enquiry)
            .then((res) => {
                dgTag({
                    event: "SubmitEnquiry",
                    category: "enquiry",
                    action: "Submit_Enquiry",
                    label: "Submit_Enquiry",
                });

                swal.fire("Success!", `${res.message}`, "success");
            })
            .catch((res) => {
                swal.fire("Error!", `${res.message}`, "error");
            });
    }

    onBookingSubmit(data) {
        const booking = {
            your_details: {
                name: data.name,
                email: data.email,
                subject: data.subject,
                message: data.message,
            },
        };
        console.log(booking);
    }

    render() {
        return (
            <div className="contact-component">
                <DgHelmet seojson={seojson}></DgHelmet>
                <TrackVisibility partialVisibility style={{ width: "100%" }}>
                    {({ isVisible }) => (
                        <Header isVisible={isVisible} title="Contact us" />
                    )}
                </TrackVisibility>
                <Enquiryform onSubmit={this.onEnquirySubmit} />
                {/*
                    <Contactmap />
                */}
                <TrackVisibility partialVisibility style={{ width: "100%" }}>
                    {({ isVisible }) => <Footervideo isVisible={isVisible} />}
                </TrackVisibility>

                {/* Modals */}
                <VimeoModal />
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    contact: state.contact,
});

export default connect(mapStateToProps, { createContact })(Contact);
