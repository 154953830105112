import React, { Component } from 'react';
import { connect } from 'react-redux';
import history from '../../../history';
import { Link } from 'react-router-dom';

// Styles
import "../../../styles/components/admin/admin.scss";

// Components
import Sidebar from '../sidebar/sidebar.admin.component';

// Actions
import { AdminPosts, fetch, selectPost } from 'redux-blogs';

class AdminPostList extends Component {

    constructor(props) {
        super(props);

        this.onSelect = this.onSelect.bind(this);
    }

    componentDidMount() {
        this.props.fetch(12, 0, '')
        .then(data => {
            console.log(data);
        })
        .catch(data => {
            console.log(data);
            alert('Failed');
        });
    }

    onSelect(post) {
        this.props.selectPost(post)
        .then(data => {
            window.scrollTo(0,0);
            history.push(`/admin/post/${post._id}`);
        });
    }

    render() {

        return (
            <div className="admin-container">
                <Sidebar />
                <main>
                    <div className="blog-navbar">
                        <ul className="blog-list">
                            <li className="blog-item">
                                <Link
                                    to={`/admin/posts/create`}
                                    className="btn-primary"
                                >
                                    + New Blog
                                </Link>
                            </li>
                        </ul>
                    </div>
                    {
                        this.props.posts.items &&
                        this.props.posts.items.length > 0 ? (
                            <AdminPosts 
                                posts={this.props.posts.items}
                                onSelect={this.onSelect}
                                headerComponent={(' ')}
                            />
                        ): ''
                    }
                </main>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    posts: state.blogs
});

export default connect(mapStateToProps, { fetch, selectPost })(AdminPostList);
