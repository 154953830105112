import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import AOS from "aos";

// Actions
import { getVideos } from "../../actions/video.action";

// Styles
import "../../styles/components/header/headerredo.scss";
import "../../../node_modules/aos/dist/aos.css";

// Components
import Navbar from "../navbar/navbar.component";
import Arrow from "./arrow";

// Assets
import logo from "../../assets/Web Assets/logo-main.svg";
import clutch from "../../assets/clutch/clutchV2.svg";

class Header extends Component {
    constructor(props) {
        super(props);

        this.state = {
            video: undefined,
        };
    }

    componentDidMount() {
        AOS.init();

        this.props
            .getVideos(
                0,
                1,
                this.props.page || "Home",
                this.props.section || "Header"
            )
            .then((res) => {
                this.setState({
                    video: res.videos[0],
                });
            })
            .catch((res) => {});
    }

    render() {
        return (
            <div className="container header-main">
                {this.props.images ? (
                    <div className="header-image">{this.props.images}</div>
                ) : this.state.video &&
                  this.state.video.files.length > 0 &&
                  this.state.video.files[0].url ? (
                    <video
                        autoPlay
                        playsInline
                        muted
                        loop
                        id="header-video"
                        style={{
                            zIndex: this.props.isVisible ? "-1" : "-2",
                            position: "fixed",
                        }}
                    >
                        <source
                            src={this.state.video.files[0].url}
                            type="video/mp4"
                        />
                        Sorry, your browser does not support video playback
                    </video>
                ) : (
                    ""
                )}

                {window.location.pathname === "/" && (
                    <div className="clutch-header">
                        <div class="clutch-widget">
                            <a
                                href="https://clutch.co/profile/reelists?utm_source=widget&utm_medium=widget_2&utm_campaign=widget&utm_content=logo"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <img
                                    src={clutch}
                                    alt="The Reelists Clutch Rating"
                                />
                            </a>
                        </div>
                    </div>
                )}

                <div className={`logo-container`}>
                    {!this.props.title && (
                        <Link to="/">
                            <img
                                src={logo}
                                alt="The Reelists logo"
                                data-aos="fade-down"
                                data-aos-delay="1200"
                            />
                        </Link>
                    )}
                    {this.props.title && this.props.title.length < 14 && (
                        <div className="section-title">
                            <h1 data-aos="fade-in" data-aos-delay="500">
                                {this.props.title}
                            </h1>
                        </div>
                    )}
                    {this.props.title && this.props.title.length >= 14 && (
                        <div className="section-title">
                            <h2 data-aos="fade-in" data-aos-delay="500">
                                {this.props.title}
                            </h2>
                        </div>
                    )}
                </div>

                <Navbar
                    title={this.props.title}
                    showLogoAtTop={
                        this.props.showLogoAtTop === undefined
                            ? true
                            : this.props.showLogoAtTop
                    }
                />

                {!this.props.title && (
                    <div className="motto-container">
                        <h4
                            data-aos="fade-right"
                            data-aos-anchor-placement="top-left"
                        >
                            {" "}
                            We produce.
                        </h4>
                        <h4
                            data-aos="fade-right"
                            data-aos-delay="300"
                            data-aos-anchor-placement="top-left"
                        >
                            {" "}
                            We shoot.
                        </h4>
                        <h4
                            data-aos="fade-right"
                            data-aos-delay="600"
                            data-aos-anchor-placement="top-left"
                        >
                            {" "}
                            We edit.
                        </h4>
                        <h4
                            data-aos="fade-right"
                            data-aos-delay="900"
                            data-aos-anchor-placement="top-left"
                        >
                            {" "}
                            We do everything.
                        </h4>
                    </div>
                )}

                <Arrow />
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    videos: state.videos,
});

export default connect(mapStateToProps, { getVideos })(Header);
