import React, { Component } from "react";
import { CMSSection } from "dg-cms";
import { DgPicture } from "dg-utils";

// Components
import ServiceTemplate from "./services.template";
import Showreelredo from "../home/showreelredo.component";
import LogoBlock from "./logo.block";

// Assets
import HeaderImage from "../../assets/service_pages/Videography/videography-hero.png";
import Image from "../../assets/service_pages/Videography/details-2.png";
import FooterImage from "../../assets/service_pages/Videography/contact-videography.png";
import HeaderImageMobile from "../../assets/service_pages/Videography/Mobile/videography-hero.png";
import ImageMobile from "../../assets/service_pages/Videography/Mobile/details-2.png";
import FooterImageMobile from "../../assets/service_pages/Videography/Mobile/contact-videography.png";
import client_logos from "../../assets/service_pages/Logos for Animation and Drone Page.png";

export default class Videography extends Component {
    render() {
        const headerImages = (cmsElmt) => (
            <DgPicture>
                <source
                    srcSet={HeaderImageMobile}
                    media="(max-width: 1024px)"
                />
                <img
                    src={HeaderImage}
                    alt={cmsElmt(
                        "Header Image Alt",
                        "text",
                        "Videography Header"
                    )}
                />
            </DgPicture>
        );

        const footerImages = (cmsElmt) => (
            <DgPicture>
                <source
                    srcSet={FooterImageMobile}
                    media="(max-width: 1024px)"
                />
                <img
                    src={FooterImage}
                    alt={cmsElmt(
                        "Header Image Alt",
                        "text",
                        "Videography Header"
                    )}
                />
            </DgPicture>
        );

        return (
            <CMSSection name="Videography Page">
                {({ cmsElmt }) => (
                    <ServiceTemplate
                        title={`Videography`}
                        page={"Videography"}
                        headerImages={headerImages(cmsElmt)}
                        footerImages={footerImages(cmsElmt)}
                        section={"Header"}
                        body={
                            <React.Fragment>
                                <CMSSection name="Videography Services">
                                    {({ cmsElmt }) => (
                                        <Showreelredo
                                            left={
                                                <div
                                                    className="showreel-title"
                                                    data-aos="fade-up"
                                                >
                                                    <h3>
                                                        Our team of
                                                        <br />
                                                        creative,
                                                        <br />
                                                        professional,
                                                        <br />
                                                        and experienced
                                                        <br />
                                                        videographers
                                                        <br />
                                                        can do exactly
                                                        <br />
                                                        what you need!
                                                    </h3>
                                                </div>
                                            }
                                            right={
                                                <div className="text-video">
                                                    <div className="showreel-para-left">
                                                        <h7>
                                                            {cmsElmt(
                                                                "Videography Paragraph 1",
                                                                "text",
                                                                "At The Reelists, we create exceptional videos for our clients, whether that be for corporate videos or explainer videos that tell your customers more about your brand, property videos to showcase homes or properties, or even videos to be used across social media, on tv, or in cinemas. Our team have that creative eye you want to produce something really special."
                                                            )}
                                                        </h7>
                                                        <h7>
                                                            {cmsElmt(
                                                                "Videography Paragraph 2",
                                                                "text",
                                                                "All of our films are unique and tailored to reflect the elegance of your brand."
                                                            )}
                                                        </h7>
                                                    </div>
                                                    <div className="showreel-vid">
                                                        {/*
                                                        <iframe
                                                            title="The Reelists Property Showreel"
                                                            src="https://player.vimeo.com/video/414007459?"
                                                            width="640"
                                                            height="360"
                                                            frameborder="0"
                                                            allow="autoplay; fullscreen; picture-in-picture"
                                                            allowfullscreen
                                                        />*/}
                                                        <DgPicture>
                                                            <source
                                                                srcSet={
                                                                    ImageMobile
                                                                }
                                                                media="(max-width: 1024px)"
                                                            />
                                                            <img
                                                                src={Image}
                                                                alt={cmsElmt(
                                                                    "Image Alt Text",
                                                                    "text",
                                                                    "Videography"
                                                                )}
                                                            />
                                                        </DgPicture>
                                                    </div>
                                                </div>
                                            }
                                        />
                                    )}
                                </CMSSection>
                                <LogoBlock
                                    logo={client_logos}
                                    logoAlt={`Property client logos`}
                                />{" "}
                            </React.Fragment>
                        }
                    />
                )}
            </CMSSection>
        );
    }
}
