import React, { Component } from "react";
import { connect } from "react-redux";
import AOS from 'aos';

// Actions
import { getVideos } from "../../actions/video.action";

// Styles
import "../../../node_modules/aos/dist/aos.css";
import '../../styles/components/header/arrow.scss';

// Components

// Assets 

class Arrow extends Component {
    constructor(props) {
        super(props);

        this.state = {
            position: 0,
            increment: 10,
            delay: 1
        }

        this.scroll = this.scroll.bind(this);
        this.scrollInterval = null;
    }

    componentDidMount() {
        AOS.init();
    }

    scroll() {
        this.setState({
            position: window.pageYOffset
        }, () => {
            this.scrollInterval = setInterval(() => {
                console.log(`position (${this.state.position}) >= height (${window.screen.height}) :: ${this.state.position >= window.screen.height}`)
                if(this.state.position >= (window.screen.height - 200)) {
                    this.setState({
                        position: 0
                    });
    
                    window.clearInterval(this.scrollInterval);
                } else {
                    window.scroll(0, this.state.position);
                    this.setState({
                        position: this.state.position + this.state.increment
                    })
                }
            }, this.state.delay);
        });
    }

    render() {
        return (
            <div className="arrow-container">
                <div className="arrow">
                    <button
                        type="button"
                        className="arrow-button"
                        onClick={() => this.scroll()}
                    >
                        <svg 
                            aria-hidden="true" 
                            focusable="false" 
                            data-prefix="fas" 
                            data-icon="angle-down" 
                            className="arrow-icon" 
                            role="img" 
                            xmlns="http://www.w3.org/2000/svg" 
                            viewBox="0 0 320 512"
                        >
                            <path 
                                fill="currentColor" 
                                d="M143 352.3L7 216.3c-9.4-9.4-9.4-24.6 0-33.9l22.6-22.6c9.4-9.4 24.6-9.4 33.9 0l96.4 96.4 96.4-96.4c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9l-136 136c-9.2 9.4-24.4 9.4-33.8 0z" 
                                className="arrow-primary"
                            />
                        </svg>
                    </button>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    videos: state.videos,
});

export default connect(mapStateToProps, { getVideos })(Arrow);