import React, { Component } from "react";
import { CMSSection } from "dg-cms";

// Components
import ServiceTemplate from "./services.template";
import Showreelredo from "../home/showreelredo.component";
import LogoBlock from "./logo.block";

// Assets
import client_logos from "../../assets/service_pages/Logos for Animation and Drone Page.png";

export default class Drone extends Component {
    render() {
        return (
            <CMSSection name="Drone Page">
                {({ cmsElmt }) => (
                    <ServiceTemplate
                        title={`Drone`}
                        page={"Drone"}
                        section={"Header"}
                        body={
                            <React.Fragment>
                                <Showreelredo
                                    left={
                                        <div
                                            className="showreel-title"
                                            data-aos="fade-up"
                                        >
                                            <h3>
                                                Drone Footage
                                                <br />
                                                on Time,
                                                <br />
                                                On Budget,
                                                <br />
                                                and Above Expectations.
                                            </h3>
                                        </div>
                                    }
                                    right={
                                        <div className="text-video">
                                            <div className="showreel-para-left">
                                                <h7>
                                                    {cmsElmt("Paragraph 1", "text", "We’re high fliers at The Reelists. Our in-house team operate state-of-the-art drones capable of capturing beautiful 4K drone video, high-quality aerial photography and stunning drone hyperlapses. Our skilled and creative pilots will showcase the best of your brand with stunning drone footage.")}
                                                </h7>
                                                <h7>
                                                    {cmsElmt("Paragraph 2", "text", "We’re fully compliant with all safety regulations, and all our operators are registered with the IAA. Our drone videography team never operate in unsafe conditions and safety is their number one concern. For added peace of mind, we can provide you with Public Indemnity Insurance and local authority permissions so that you know our productions are always fully compliant. Take a look at our drone videos in the showreel below to see a little of what we can do!")}
                                                </h7>
                                            </div>
                                            <div className="showreel-vid">
                                                <iframe
                                                    title="The Reelists Drone Showreel"
                                                    src="https://player.vimeo.com/video/503166139?"
                                                    width="640"
                                                    height="360"
                                                    frameborder="0"
                                                    allow="autoplay; fullscreen; picture-in-picture"
                                                    allowfullscreen
                                                />
                                            </div>
                                        </div>
                                    }
                                />
                                <LogoBlock
                                    logo={client_logos}
                                    logoAlt={`Drone client logos`}
                                />
                            </React.Fragment>
                        }
                    />
                )}
            </CMSSection>
        );
    }
}
