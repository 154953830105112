import { 
    SET_VIDEO_MODAL
} from './types';

export const setVideoModal = (data) => dispatch => {
    return new Promise((resolve, reject) => {
        dispatch({
            type: SET_VIDEO_MODAL,
            payload: data
        });

        resolve(data);
    });
};