export const ourWorkVideos = [
    {
        title: "Island's Edge",
        id: "640406393",
        img: "https://storage.thereelists.com/videoThumbnails/Our+Work+Page/islands_edge.jpg",
        alt: "Island's Edge",
    },
    {
        title: "Epilepsy Ireland",
        id: "680872067",
        img: "https://storage.thereelists.com/videoThumbnails/Our+Work+Page/Havas_Epilepsy.jpg",
        alt: "Epilepsy Ireland",
    },
    {
        title: "Audi",
        id: "604212655",
        img: "https://storage.thereelists.com/videoThumbnails/Our+Work+Page/Audi.jpg",
        alt: "Audi",
    },
    {
        title: "Marie Keating Foundation | Breast Cancer Isn't Just Pink",
        id: "475598754",
        img: "https://storage.thereelists.com/videoThumbnails/Our+Work+Page/MKF.jpg",
        alt: "Marie Keating Foundation | Breast Cancer Isn't Just Pink",
    },
    {
        title: "UCD Michael Smurfit Business School",
        id: "764639530",
        img: "https://storage.thereelists.com/videoThumbnails/home+page/UCD.png",
        alt: "UCD Michael Smurfit Business School",
    },
    {
        title: "NWC | Balance the Odds",
        id: "604217426",
        img: "https://storage.thereelists.com/videoThumbnails/home+page/NWC_Boards_Hero.jpg",
        alt: "NWC | Balance the Odds",
    },
    {
        title: "We Act",
        id: "656067746",
        img: "https://storage.thereelists.com/videoThumbnails/home+page/We_Act.jpg",
        alt: "JCD | Penrose Dock",
    },
    {
        title: "JustEat",
        id: "696503271",
        img: "https://storage.thereelists.com/videoThumbnails/Our+Work+Page/JustEat_Sing_praises.jpg",
        alt: "JustEat",
    },
    {
        title: "Go Visit Donegal | Families",
        id: "641141714",
        img: "https://storage.thereelists.com/videoThumbnails/home+page/Donegal.jpg",
        alt: "Go Visit Donegal",
    },
    {
        title: "Microfinance Ireland | Alpaca Joe",
        id: "604215255",
        img: "https://storage.thereelists.com/videoThumbnails/home+page/MFI_AlpacaJoe.jpg",
        alt: "Microfinance Ireland | Alpaca Joe",
    },
    {
        title: "LADbible",
        id: "656245218",
        img: "https://storage.thereelists.com/videoThumbnails/home+page/LADbible.jpg",
        alt: "LADbible",
    },
    {
        title: "Sonbrook | Merchant Buildings",
        id: "479870130",
        img: "https://storage.thereelists.com/videoThumbnails/home+page/Sonbrook.jpg",
        alt: "Sonbrook | Merchant Buildings",
    },

    {
        title: "Trail Kilkenny",
        id: "506768989",
        img: "https://storage.thereelists.com/videoThumbnails/home+page/Trail_Kilkenny_1.jpg",
        alt: "Trail Kilkenny",
    },
    {
        title: "NWC | FemFest",
        id: "343201070",
        img: "https://storage.thereelists.com/videoThumbnails/home+page/femfest.jpg",
        alt: "NWC | FemFest",
    },
    {
        title: "BelongTo",
        id: "686254877",
        img: "https://storage.thereelists.com/videoThumbnails/Our+Work+Page/Language_BelongTo.jpg",
        alt: "BelongTo",
    },
    {
        title: "TFY | Check The Register",
        id: "298368060",
        img: "https://storage.thereelists.com/videoThumbnails/home+page/Check_the_register.jpg",
        alt: "TFY | Check The Register",
    },
    {
        title: "Citizens Assembly | A Strong Call for Change",
        id: "540383869",
        img: "https://storage.thereelists.com/videoThumbnails/Our+Work+Page/Citizens_Assembly.jpg",
        alt: "Citizens Assembly | A Strong Call for Change",
    },
    {
        title: "DCC | Waste to Energy",
        id: "622588020",
        img: "https://storage.thereelists.com/videoThumbnails/Our+Work+Page/DCC_Green_Future.jpg",
        alt: "DCC | Waste to Energy",
    },
    {
        title: "Leargas",
        id: "567227000",
        img: "https://storage.thereelists.com/videoThumbnails/Our+Work+Page/Leargas.jpg",
        alt: "Leargas",
    },
    {
        title: "Maxol | Top up by 10",
        id: "298366170",
        img: "https://storage.thereelists.com/videoThumbnails/Our+Work+Page/Maxol.jpg",
        alt: "Maxol | Top up by 10",
    },
    {
        title: "Solas | This is FET",
        id: "440314009",
        img: "https://storage.thereelists.com/videoThumbnails/Our+Work+Page/FET.jpg",
        alt: "Solas | This is FET",
    },
    {
        title: "Pillow Queens | Puppets",
        id: "298366283",
        img: "https://storage.thereelists.com/videoThumbnails/Our+Work+Page/puppets.jpg",
        alt: "Pillow Queens | Puppets",
    },
    {
        title: "PHCP | Nutrition",
        id: "417661947",
        img: "https://storage.thereelists.com/videoThumbnails/home+page/PHCP_Nutrition.jpg",
        alt: "PHCP | Nutrition",
    },
    {
        title: "Together For Yes Crowdfunding",
        id: "299862764",
        img: "https://storage.thereelists.com/videoThumbnails/Our+Work+Page/Crowdfund.jpg",
        alt: "Together For Yes Crowdfunding",
    },
    {
        title: "Social Democrats | Hope for Better, Vote for Better",
        id: "389322340",
        img: "https://storage.thereelists.com/videoThumbnails/Our+Work+Page/Social_Democrats.jpg",
        alt: "Social Democrats | Hope for Better, Vote for Better",
    },
    {
        title: "Husqvarna",
        id: "585548526",
        img: "https://storage.thereelists.com/videoThumbnails/home+page/Husqvarna.jpg",
        alt: "Husqvarna",
    },
    {
        title: "Ironman",
        id: "357814153",
        img: "https://storage.thereelists.com/videoThumbnails/Our+Work+Page/ironman.jpg",
        alt: "Ironman",
    },
];

export const agencyVideos = [
    {
        title: "Island's Edge",
        id: "640406393",
        img: "https://storage.thereelists.com/videoThumbnails/Our+Work+Page/islands_edge.jpg",
        alt: "Island's Edge",
    },
    {
        title: "Epilepsy Ireland",
        id: "680872067",
        img: "https://storage.thereelists.com/videoThumbnails/Our+Work+Page/Havas_Epilepsy.jpg",
        alt: "Epilepsy Ireland",
    },
    {
        title: "Audi",
        id: "604212655",
        img: "https://storage.thereelists.com/videoThumbnails/Our+Work+Page/Audi.jpg",
        alt: "Audi",
    },

    {
        title: "Marie Keating Foundation | Breast Cancer Isn't Just Pink",
        id: "475598754",
        img: "https://storage.thereelists.com/videoThumbnails/Our+Work+Page/MKF.jpg",
        alt: "Marie Keating Foundation | Breast Cancer Isn't Just Pink",
    },
    {
        title: "JustEat",
        id: "696503271",
        img: "https://storage.thereelists.com/videoThumbnails/Our+Work+Page/JustEat_Sing_praises.jpg",
        alt: "JustEat",
    },
    {
        title: "NWC | Balance The Odds",
        id: "604217426",
        img: "https://storage.thereelists.com/videoThumbnails/home+page/NWC_Boards_Hero.jpg",
        alt: "NWC | Balance The Odds",
    },
    {
        title: "LADbible",
        id: "656245218",
        img: "https://storage.thereelists.com/videoThumbnails/home+page/LADbible.jpg",
        alt: "LADbible",
    },
    {
        title: "Windsor Motors | Battle of the Hemispheres",
        id: "298365643",
        img: "https://storage.thereelists.com/videoThumbnails/home+page/Windsor.jpg",
        alt: "Windsor Motors | Battle of the Hemispheres",
    },
    {
        title: "Stephens Green | Counting Christmas",
        id: "378352920",
        img: "https://storage.thereelists.com/videoThumbnails/Agencies+page/Counting_Christmas.jpg",
        alt: "Stephens Green | Counting Christmas",
    },
    {
        title: "Go Visit Donegal | Families",
        id: "641141714",
        img: "https://storage.thereelists.com/videoThumbnails/home+page/Donegal.jpg",
        alt: "Go Visit Donegal",
    },
    {
        title: "Microfinance Ireland | Alpaca Joe",
        id: "604215255",
        img: "https://storage.thereelists.com/videoThumbnails/home+page/MFI_AlpacaJoe.jpg",
        alt: "Microfinance Ireland | Alpaca Joe",
    },
    {
        title: "GSK | Smile Trial",
        id: "480851381",
        img: "https://storage.thereelists.com/videoThumbnails/Agencies+page/Smile_Trial_GSK.jpg",
        alt: "GSK | Smile Trial",
    },
    {
        title: "Boys & Girls | First Data",
        id: "437217285",
        img: "https://storage.thereelists.com/videoThumbnails/Agencies+page/First_Data.jpg",
        alt: "Boys & Girls | First Data",
    },
    {
        title: "Maxol | Top up by 10",
        id: "298366170",
        img: "https://storage.thereelists.com/videoThumbnails/Our+Work+Page/Maxol.jpg",
        alt: "Maxol | Top up by 10",
    },
    {
        title: "Pillow Queens | Puppets",
        id: "298366283",
        img: "https://storage.thereelists.com/videoThumbnails/Our+Work+Page/puppets.jpg",
        alt: "Pillow Queens | Puppets",
    },
];

export const charityVideos = [
    {
        title: "Marie Keating Foundation | Breast Cancer Isn't Just Pink",
        id: "475598754",
        img: "https://storage.thereelists.com/videoThumbnails/Our+Work+Page/MKF.jpg",
        alt: "Marie Keating Foundation | Breast Cancer Isn't Just Pink",
    },
    {
        title: "We Act",
        id: "656067746",
        img: "https://storage.thereelists.com/videoThumbnails/home+page/We_Act.jpg",
        alt: "JCD | Penrose Dock",
    },
    {
        title: "Citizens Assembly | A Strong Call for Change",
        id: "540383869",
        img: "https://storage.thereelists.com/videoThumbnails/Our+Work+Page/Citizens_Assembly.jpg",
        alt: "Citizens Assembly | A Strong Call for Change",
    },
    {
        title: "NWC | FemFest",
        id: "343201070",
        img: "https://storage.thereelists.com/videoThumbnails/home+page/femfest.jpg",
        alt: "NWC | FemFest",
    },
    {
        title: "Epilepsy Ireland",
        id: "680872067",
        img: "https://storage.thereelists.com/videoThumbnails/Our+Work+Page/Havas_Epilepsy.jpg",
        alt: "Epilepsy Ireland",
    },
    {
        title: "NWC | Balance the Odds",
        id: "604217426",
        img: "https://storage.thereelists.com/videoThumbnails/home+page/NWC_Boards_Hero.jpg",
        alt: "NWC | Balance the Odds",
    },
    {
        title: "Primark | International Women's Day",
        id: "405909603",
        img: "https://storage.thereelists.com/videoThumbnails/Public+Communications+Page/Primark_Women's_Day.jpg",
        alt: "Primark | International Women's Day",
    },
    {
        title: "Anti Racism Ad",
        id: "476057726",
        img: "https://storage.thereelists.com/videoThumbnails/Our+Work+Page/IHREC_Racism.jpg",
        alt: "Anti Racism Ad",
    },
    {
        title: "BelongTo",
        id: "686254877",
        img: "https://storage.thereelists.com/videoThumbnails/Our+Work+Page/Language_BelongTo.jpg",
        alt: "BelongTo",
    },
    {
        title: "TFY Crowdfunding",
        id: "299862764",
        img: "https://storage.thereelists.com/videoThumbnails/Our+Work+Page/Crowdfund.jpg",
        alt: "TFY Crowdfunding",
    },
    {
        title: "Leargas",
        id: "567227000",
        img: "https://storage.thereelists.com/videoThumbnails/Our+Work+Page/Leargas.jpg",
        alt: "Leargas",
    },
    {
        title: "Tuath",
        id: "703649001",
        img: "https://storage.thereelists.com/videoThumbnails/Our+Work+Page/Tuath.jpg",
        alt: "Tuath",
    },
    {
        title: "TFY | Check The Register",
        id: "298368060",
        img: "https://storage.thereelists.com/videoThumbnails/home+page/Check_the_register.jpg",
        alt: "TFY | Check The Register",
    },
    {
        title: "Maxol | Top up by 10",
        id: "298366170",
        img: "https://storage.thereelists.com/videoThumbnails/Our+Work+Page/Maxol.jpg",
        alt: "Maxol | Top up by 10",
    },
    {
        title: "Social Democrats",
        id: "389322340",
        img: "https://storage.thereelists.com/videoThumbnails/Our+Work+Page/Social_Democrats.jpg",
        alt: "Social Democrats",
    },
];

export const propertyVideos = [
    {
        title: "JCD | Penrose Dock",
        id: "604226714",
        img: "https://storage.thereelists.com/videoThumbnails/Properties+Page/Penrose_Dock.jpg",
        alt: "JCD | Penrose Dock",
    },
    {
        title: "Merchant Buildings",
        id: "479870130",
        img: "https://storage.thereelists.com/videoThumbnails/Properties+Page/Sonbrook.jpg",
        alt: "Merchant Buildings",
    },
    {
        title: "The Davitt",
        id: "359325695",
        img: "https://storage.thereelists.com/videoThumbnails/Properties+Page/The_Davitt.jpg",
        alt: "The Davitt",
    },
    {
        title: "The Quarter at Citywest",
        id: "350289474",
        img: "https://storage.thereelists.com/videoThumbnails/Properties+Page/Citywest.jpg",
        alt: "The Quarter at Citywest",
    },
    {
        title: "Waterside",
        id: "298377200",
        img: "https://storage.thereelists.com/videoThumbnails/Properties+Page/waterside.jpg",
        alt: "Waterside",
    },
    {
        title: "Kingsland",
        id: "323226101",
        img: "https://storage.thereelists.com/videoThumbnails/Properties+Page/kingsland.jpg",
        alt: "Kingsland",
    },
    {
        title: "The Willows",
        id: "323225527",
        img: "https://storage.thereelists.com/videoThumbnails/Properties+Page/thewillows.jpg",
        alt: "The Willows",
    },
    {
        title: "The Blake",
        id: "405071489",
        img: "https://storage.thereelists.com/videoThumbnails/Properties+Page/The_Blake.jpg",
        alt: "The Blake",
    },
    {
        title: "Prestige Portfolio",
        id: "413624548",
        img: "https://storage.thereelists.com/videoThumbnails/Properties+Page/Prestige_Portfolio.jpg",
        alt: "Prestige Portfolio",
    },
];

export const animationVideos = [
    {
        title: "SOLAS FET",
        id: "440314009",
        img: "https://storage.thereelists.com/videoThumbnails/animations/FET.jpg",
        alt: "SOLAS FET",
    },
    {
        title: "USI",
        id: "473850824",
        img: "https://storage.thereelists.com/videoThumbnails/animations/USI.jpg",
        alt: "USI",
    },
    {
        title: "PHCP",
        id: "469758816",
        img: "https://storage.thereelists.com/videoThumbnails/animations/PHCP_Video3.jpg",
        alt: "PHCP",
    },
    {
        title: "Harvey Norman Click & Scale",
        id: "434011107",
        img: "https://storage.thereelists.com/videoThumbnails/animations/HN_Click_And_Scale_Thumbnail.jpg",
        alt: "Harvey Norman Click & Scale",
    },
    {
        title: "DC4 Culture Map",
        id: "469759134",
        img: "https://storage.thereelists.com/videoThumbnails/animations/DC4_Culture_Near_You.jpg",
        alt: "DC4 Culture Map",
    },
    {
        title: "Writing a Kick Ass Marketing Plan",
        id: "298352409",
        img: "https://storage.thereelists.com/videoThumbnails/animations/DCC+kick+ass+marketing+plan.jpg",
        alt: "Writing a Kick Ass Marketing Plan",
    },
    {
        title: "How To Vote",
        id: "299701205",
        img: "https://storage.thereelists.com/videoThumbnails/animations/How_to_Vote.jpg",
        alt: "How To Vote",
    },
    {
        title: "Know Your Rights",
        id: "389976077",
        img: "https://storage.thereelists.com/videoThumbnails/animations/EAPN_Know_your_Rights.jpg",
        alt: "Know Your Rights",
    },
    {
        title: "Leadership Election",
        id: "403335604",
        img: "https://storage.thereelists.com/videoThumbnails/animations/Leadership_Election.jpg",
        alt: "Leadership Election",
    },
];
