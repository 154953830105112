import React, { Component } from 'react';
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import AOS from 'aos';

// Actions
import { mobilenav } from "../../actions/mobilenav.action";

// Styles
import "../../styles/components/navbar/navbar.scss";

// Assets
import menuicon from "../../assets/menu_icon.png";

// Components
import Sidedrawer from "../sidedrawer/sidedrawer.component";

class Navbar extends Component {

  constructor(props) {
    super(props);

    this.state = {
      position: 0,
      showStuckMenu: false
    };

    this.listenToScroll = this.listenToScroll.bind(this);
  }

  componentDidMount() {
    window.addEventListener('scroll', this.listenToScroll);
    AOS.init();
  }
  
  componentWillUnmount() {
    window.removeEventListener('scroll', this.listenToScroll);
  }

  listenToScroll() {
    let position = window.pageYOffset;
    let showStuckMenu = (window.pageYOffset >= 100);

    this.setState({
      position: position,
      showStuckMenu: showStuckMenu
    });
  }

  render() {
    return (
      <div className={`navbar ${this.state.showStuckMenu ? `headerStuck` : ''}`}>
        {
          (
            (
              this.props.showLogoAtTop
            ) ||
            (
              this.props.showLogoAtTop === false &&
              this.state.position !== 0
            )
          ) ? (
            <div className="navbar-logo-container">
              <Link
                to="/"
              >
                
              <svg 
                xmlns="http://www.w3.org/2000/svg" 
                viewBox="0 0 217 124"
                width="217" 
                height="124"
                className="logo-navbar"
              >
                <defs>
                  <clipPath clipPathUnits="userSpaceOnUse" id="cp1">
                    <path d="M-87 -4312L1833 -4312L1833 1020L-87 1020Z" />
                  </clipPath>
                </defs>
                <g id="About Us" clip-path="url(#cp1)">
                  <g id="-e-logo">
                    <g id="The Reelists">
                      <g id="Group 36">
                        <g id="Group 25">
                          <path id="Path 23" className="primary" d="M23.05 8.95L23.03 16.08L15.43 17.15L15.35 59.97L8.08 61L8.16 18.18L0.55 19.24L0.56 12.11L23.05 8.95Z" />
                        </g>
                        <g id="Group 26">
                          <path id="Path 24" className="primary" d="M54.12 4.58L54.03 54.54L46.62 55.58L46.66 34.17L38.4 35.33L38.36 56.74L31.08 57.76L31.18 7.8L38.45 6.78L38.41 28.19L46.68 27.03L46.71 5.62L54.12 4.58Z" />
                        </g>
                        <g id="Group 27">
                          <path id="Path 25" className="primary" d="M84.2 0.35L84.19 7.49L71.63 9.25L71.6 23.17L81.59 21.77L81.57 28.9L71.59 30.31L71.56 44.94L84.12 43.17L84.11 50.31L64.27 53.1L64.37 3.14L84.2 0.35Z" />
                        </g>
                        <g id="Group 28">
                          <path id="Path 26" fill-rule="evenodd" className="primary" d="M24.3 114.01C24.3 116.44 24.36 118.21 25.08 119.96L17.68 121.01C17.29 119.78 17.02 119.03 17.03 114.96L17.05 107.11C17.05 102.47 15.6 100.96 12.3 101.43L9.78 101.78L9.75 122.12L2.47 123.14L2.57 73.19L13.54 71.64C21.08 70.58 24.31 73.91 24.3 81.62L24.29 85.54C24.28 90.68 22.75 94.18 19.51 96.28L19.51 96.42C23.14 97.55 24.33 101.09 24.32 106.3L24.3 114.01ZM17.01 88.35L17.02 83.43C17.03 79.86 15.91 78.45 13.33 78.81L9.83 79.3L9.8 94.64L12.64 94.24C15.35 93.86 17.01 92.35 17.01 88.35Z" />
                        </g>
                        <g id="Group 29">
                          <path id="Path 27" className="primary" d="M53.94 65.97L53.93 73.1L41.36 74.87L41.34 88.78L51.32 87.38L51.31 94.52L41.32 95.92L41.3 110.55L53.86 108.79L53.85 115.92L34.01 118.71L34.11 68.75L53.94 65.97Z" />
                        </g>
                        <g id="Group 30">
                          <path id="Path 28" className="primary" d="M83.23 61.85L83.22 68.99L70.65 70.75L70.63 84.67L80.61 83.26L80.6 90.4L70.61 91.8L70.59 106.44L83.15 104.67L83.14 111.81L63.3 114.59L63.39 64.64L83.23 61.85Z" />
                        </g>
                        <g id="Group 31">
                          <path id="Path 29" className="primary" d="M99.96 59.5L99.88 102.32L111.85 100.64L111.83 107.77L92.59 110.48L92.69 60.52L99.96 59.5Z" />
                        </g>
                        <g id="Group 32">
                          <path id="Path 30" className="primary" d="M127.33 55.65L127.24 105.61L119.96 106.63L120.06 56.67L127.33 55.65Z" />
                        </g>
                        <g id="Group 33">
                          <path id="Path 31" className="primary" d="M147.37 52.26C154.44 51.27 158.07 55.32 158.05 63.32L158.05 64.89L151.18 65.86L151.18 63.79C151.19 60.22 149.87 59.05 147.55 59.37C145.24 59.7 143.91 61.24 143.91 64.81C143.9 68.45 145.35 70.95 150.1 74.78C156.17 79.71 158.08 83.58 158.07 89.29C158.06 97.28 154.34 102.37 147.2 103.37C140.06 104.38 136.37 100.33 136.38 92.34L136.39 89.27L143.27 88.3L143.26 91.87C143.25 95.44 144.7 96.52 147.02 96.19C149.33 95.87 150.79 94.38 150.8 90.81C150.8 87.17 149.36 84.66 144.6 80.83C138.53 75.91 136.62 72.04 136.63 66.33C136.65 58.34 140.29 53.26 147.37 52.26Z" />
                        </g>
                        <g id="Group 34">
                          <path id="Path 32" className="primary" d="M187.63 47.17L187.62 54.31L180.02 55.38L179.93 98.2L172.66 99.22L172.74 56.4L165.14 57.47L165.15 50.33L187.63 47.17Z" />
                        </g>
                        <g id="Group 35">
                          <path id="Path 33" className="primary" d="M205.55 44.09C212.63 43.09 216.25 47.15 216.24 55.14L216.24 56.71L209.36 57.68L209.36 55.61C209.37 52.04 208.05 50.87 205.74 51.19C203.42 51.52 202.1 53.06 202.09 56.63C202.08 60.27 203.53 62.78 208.28 66.6C214.36 71.53 216.27 75.4 216.26 81.11C216.24 89.1 212.53 94.19 205.39 95.19C198.25 96.2 194.55 92.15 194.57 84.16L194.57 81.09L201.45 80.12L201.44 83.69C201.44 87.26 202.89 88.34 205.2 88.01C207.52 87.69 208.98 86.2 208.98 82.63C208.99 78.99 207.54 76.48 202.79 72.66C196.72 67.73 194.81 63.86 194.82 58.15C194.83 50.16 198.48 45.08 205.55 44.09Z" />
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </svg>
              {/*
              <svg 
                xmlns="http://www.w3.org/2000/svg" 
                viewBox="0 0 500 150" 
                preserveAspectRatio="none"
                className="underline"
              >
                <path 
                  d="M7.7,145.6C109,125,299.9,116.2,401,121.3c42.1,2.2,87.6,11.8,87.3,25.7"
                >
                </path>
              </svg>
              */}
              </Link> 
            </div>
          ) : ''
        }
        <div className="menu">
          <button
            type="button"
            className="menu-icon"
            onClick={this.props.mobilenav}
          >
            <img src={menuicon} alt="menu icon" />
          </button>
        </div>
        <Sidedrawer />
      </div>
    )
  }
}

export default connect(null, { mobilenav })(Navbar);