import React from 'react';
import { Field, reduxForm } from 'redux-form';
import '../../styles/components/login.scss';

const LoginForm = props => {
  const { handleSubmit, pristine, submitting } = props
  return (
        <form onSubmit={handleSubmit}>
            <div className="form-group">
                <label>Email address</label>
                <Field type="email" name="email" component="input" placeholder="Enter email" required />
            </div>
            <div className="form-group">
                <label>Password</label>
                <Field type="password" name="password" component="input" placeholder="Password" required />
            </div>
            <div className="form-group">
                <button type="submit" className="btn-primary" disabled={pristine || submitting}>Login</button>
            </div>
        </form>
  )
}

export default reduxForm({
  form: 'LoginForm' // a unique identifier for this form
})(LoginForm)