import React, { Component } from "react";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import { mapcolors } from "../../assets/files/mapcolors";

const containerStyle = {
    width: "100%",
    height: "100%",
};

const center = {
    lat: 53.33377771516405,
    lng: -6.244969159335494
};

const defaultOptions = {
    styles: mapcolors,
    disableDefaultUI: true,
};

class GMaps extends Component {
    render() {
        return (
            <LoadScript googleMapsApiKey="AIzaSyDxizgvGaZ6ndU2h1sHCOsx9-jXtLFC69Q">
                <GoogleMap
                    mapContainerStyle={containerStyle}
                    center={center}
                    zoom={13}
                    options={defaultOptions}
                >
                    {/* Child components, such as markers, info windows, etc. */}
                    <Marker position={center} />
                </GoogleMap>
            </LoadScript>
        );
    }
}

export default GMaps;
