import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import { reducer as formReducer } from "redux-form";
import { reducer as blogReducer } from "redux-blogs";
import loginReducer from "./login.reducer";
import postReducer from "./post.reducer";
import visibleReducer from "./visible.reducer";
import mobilenavReducer from "./mobilenav.reducer";
import contactReducer from "./contact.reducer";
import bookingReducer from "./booking.reducer";
import videoReducer from "./video.reducer";
import modalsReducer from "./modal.reducer";
import { reducer as cmsReducer } from "dg-cms";

const createRootReducer = (history) =>
    combineReducers({
        router: connectRouter(history),
        loggedInUser: loginReducer,
        form: formReducer,
        blogs: blogReducer,
        posts: postReducer,
        contact: contactReducer,
        booking: bookingReducer,
        visible: visibleReducer,
        mobilenav: mobilenavReducer,
        videos: videoReducer,
        modals: modalsReducer,
        cms: cmsReducer,
    });

export default createRootReducer;
