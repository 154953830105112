import { 
    FETCH_POSTS, 
    NEW_POST, 
    SELECT_POST, 
    CLEAR_POST_FORM,
    SET_UPLOADS,
    SET_NUM_BLOG_POSTS
} from '../actions/types';

const initialState = {
    numBlogPosts: 6,
    items: [],
    item: {},
    uploads: [],
    currentPost: {},
    clearForm: true
};

export default function (state = initialState, action) {
    switch(action.type) {
        case SET_NUM_BLOG_POSTS:
            return  {
                ...state,
                numBlogPosts: action.payload
            }
        case SET_UPLOADS:
            return  {
                ...state,
                uploads: action.payload
            }
        case FETCH_POSTS: 
            return {
                ...state,
                items: action.payload
            }
        case NEW_POST: 
            return {
                ...state,
                item: action.payload
            }
        case SELECT_POST: 
            return {
                ...state,
                currentPost: action.payload
            }
        case CLEAR_POST_FORM: 
            return {
                ...state,
                clearForm: action.payload
            }
        default:
            return state;
    }
}