import { MOBILENAV } from "../actions/types";

export default function (state = false, action) {
    switch (action.type) {
        case MOBILENAV:
            return !state;
        default:
            return state;
    }
}
