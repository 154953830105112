import React, { Component } from "react";
import { DgHelmet } from "dg-utils";
import { CMSSection } from "dg-cms";

import "../../styles/components/about/about.scss";

import Header from "../header/header.component";
import Textcomponent from "./text.component";
import Footervideo from "../footervideo/footervideo.component.js";
import Videobg from "./videobg.component";
import TrackVisibility from "react-on-screen";
import Team from "../whoweare/team.component";
import Studio from "../whoweare/studio.component";
import VimeoModal from "../modals/vimeoModal/vimeoModal";
// AOS
import AOS from "aos";

import clients from "../../assets/Web Assets/backgrounds/Client_Logos.png";
import seojson from "../../assets/files/seojson.js";

export default class About extends Component {
    componentDidMount() {
        AOS.init();
    }

    render() {
        return (
            <CMSSection name="About">
                {({ cmsElmt }) => (
                    <div className="about">
                        <DgHelmet seojson={seojson}></DgHelmet>
                        <TrackVisibility
                            partialVisibility
                            style={{ width: "100%" }}
                        >
                            {({ isVisible }) => (
                                <Header
                                    isVisible={isVisible}
                                    title="About us"
                                    page={"About"}
                                    section={"Header"}
                                    showLogoAtTop={true}
                                />
                            )}
                        </TrackVisibility>
                        <Textcomponent
                            color="pink"
                            content={
                                <React.Fragment>
                                    <h5>
                                        {cmsElmt(
                                            "About Intro",
                                            "text",
                                            "The Reelists’ story begins in the basement office of an old dilapidated fire station. From those humble beginnings we’ve grown to become one of Ireland’s most respected video production companies."
                                        )}
                                    </h5>
                                    <br />
                                    <h5>
                                        {cmsElmt(
                                            "About Intro 2",
                                            "text",
                                            "Our philosophy is “We Do Everything”, and we take this seriously. Not only do we provide every kind of video-related service imaginable, but we also handle every step of the process, from coming up with ideas all the way to advising on how best to maximise your video views."
                                        )}
                                    </h5>
                                </React.Fragment>
                            }
                        />
                        <TrackVisibility
                            partialVisibility
                            style={{ width: "100%" }}
                        >
                            {({ isVisible }) => (
                                <Videobg isVisible={isVisible} />
                            )}
                        </TrackVisibility>
                        <div className="container our-clients">
                            <div className="our-clients-wrapper">
                                <h2 data-aos="fade-up"> Our Clients</h2>
                                <img
                                    data-aos="fade-in"
                                    src={clients}
                                    alt="clients logos"
                                />
                            </div>
                        </div>
                        {/*
                            <Team />
                            <Studio />
                        */}
                        <TrackVisibility
                            partialVisibility
                            style={{ width: "100%" }}
                        >
                            {({ isVisible }) => (
                                <Footervideo isVisible={isVisible} />
                            )}
                        </TrackVisibility>

                        {/* Modals */}
                        <VimeoModal />
                    </div>
                )}
            </CMSSection>
        );
    }
}
