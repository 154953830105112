import React, { Component } from "react";
import TrackVisibility from "react-on-screen";
import { DgHelmet } from "dg-utils";
import { CMSSection } from "dg-cms";

// Components
import Header from "../header/header.component.js";
import Showreelredo from "./showreelredo.component.js";
//import Testimonials from "./testimonials.component.js";
import Testimonialsredo from "./testimonialsredo.component.js";
import Morework from "./morework.component.js";
import Services from "./services.component.js";
import Footervideo from "../footervideo/footervideo.component.js";
import Team from "./team.component.js";
import VimeoModal from "../modals/vimeoModal/vimeoModal";

import seojson from "../../assets/files/seojson.js";

export default class Home extends Component {
    constructor(props) {
        super(props);

        this.myRef = React.createRef();
    }

    render() {
        return (
            <CMSSection name="Home">
                {({ cmsElmt }) => (
                    <div className="home">
                        <DgHelmet seojson={seojson}></DgHelmet>
                        <TrackVisibility
                            partialVisibility
                            style={{ width: "100%" }}
                        >
                            {({ isVisible }) => (
                                <Header
                                    isVisible={isVisible}
                                    showLogoAtTop={false}
                                />
                            )}
                        </TrackVisibility>

                        <Showreelredo />
                        <Testimonialsredo />
                        <TrackVisibility
                            partialVisibility
                            style={{ width: "100%" }}
                        >
                            {({ isVisible }) => (
                                <Morework isVisible={isVisible} />
                            )}
                        </TrackVisibility>
                        <CMSSection name="So What Do We Do?">
                            {({ cmsElmt }) => (
                                <Services
                                    title={`So, what do we do?`}
                                    content={
                                        <div
                                            className="services-text-container"
                                            data-aos="fade-right"
                                        >
                                            <div className="service-para">
                                                <p>
                                                    {cmsElmt(
                                                        "Intro Text",
                                                        "text",
                                                        "We know that creating a video can be\n daunting, be it a TV ad, a corporate\nvideo, or a simple videography\nassignment. We make video production\n as easy as possible for all our\nclients by handling every step of\n the videography process from start\n to finish."
                                                    )}
                                                </p>
                                            </div>
                                            <div className="service-para">
                                                <h5 className="service-title">
                                                    {cmsElmt(
                                                        "Service 1 Header",
                                                        "text",
                                                        "We produce. "
                                                    )}
                                                </h5>
                                                <p>
                                                    {cmsElmt(
                                                        "Service 1 Text",
                                                        "text",
                                                        "We discuss your brand and\n your goals for your video\n production. What story do\n you want to tell and who do\n you want to reach? Every\n video we make is completely\n unique and focused on your\n message."
                                                    )}
                                                </p>
                                            </div>
                                            <div className="service-para">
                                                <h5 className="service-title">
                                                    {cmsElmt(
                                                        "Service 2 Header",
                                                        "text",
                                                        "We shoot. "
                                                    )}
                                                </h5>
                                                <p>
                                                    {cmsElmt(
                                                        "Service 2 Text",
                                                        "text",
                                                        "This is the fun part!\n Whether it’s one of our\n talented videographers, or a\n film crew of 20, you’ll be\n working with a team of\n Reelists dedicated to\n telling your story using\n only the best video\n production equipment."
                                                    )}
                                                </p>
                                            </div>
                                            <div className="service-para">
                                                <h5 className="service-title">
                                                    {cmsElmt(
                                                        "Service 3 Header",
                                                        "text",
                                                        "We edit. "
                                                    )}
                                                </h5>
                                                <p>
                                                    {cmsElmt(
                                                        "Service 3 Text",
                                                        "text",
                                                        "Our team of editors get to\n work cutting your video,\n adding animation, 3D\n graphics and cinematic music\n to really grab people’s\n attention. We leave plenty\n of time for your feedback,\n so you can be sure the\n finished video is one you’re\n truly happy with."
                                                    )}
                                                </p>
                                            </div>
                                            <div className="service-para">
                                                <h5 className="service-title">
                                                    {cmsElmt(
                                                        "Closing Text",
                                                        "text",
                                                        "And we deliver. On time. On\n budget. And above\n expectations."
                                                    )}
                                                </h5>
                                            </div>
                                        </div>
                                    }
                                />
                            )}
                        </CMSSection>
                        <TrackVisibility
                            partialVisibility
                            style={{ width: "100%" }}
                        >
                            {({ isVisible }) => <Team isVisible={isVisible} />}
                        </TrackVisibility>
                        <TrackVisibility
                            partialVisibility
                            style={{ width: "100%" }}
                        >
                            {({ isVisible }) => (
                                <Footervideo isVisible={isVisible} />
                            )}
                        </TrackVisibility>

                        {/* Modals */}
                        <VimeoModal />
                    </div>
                )}
            </CMSSection>
        );
    }
}
