import React, { Component } from "react";
import TrackVisibility from "react-on-screen";
import { CMSSection } from "dg-cms";

// Components
import ServiceTemplate from "./services.template";
import Showreelredo from "../home/showreelredo.component";
import LogoBlock from "./logo.block";
import VideoStack from "../ourwork/videosStack.component";
import Team from "../home/team.component";

// Assets
import { charityVideos } from "../../assets/files/vimeolinks";
import client_logos from "../../assets/service_pages/Logos for Charity Page.png";

export default class Charities extends Component {
    render() {
        return (
            <CMSSection name="Public Communications Page">
                {({ cmsElmt }) => (
                    <ServiceTemplate
                        title={`Public Communications`}
                        page={"Charities"}
                        section={"Header"}
                        body={
                            <React.Fragment>
                                <CMSSection name="Public Communications Services">
                                    {({ cmsElmt }) => (
                                        <Showreelredo
                                            left={
                                                <div
                                                    className="showreel-title"
                                                    data-aos="fade-up"
                                                >
                                                    <h2>
                                                        Videos that raise
                                                        awareness and influence
                                                        public opinion.
                                                    </h2>
                                                </div>
                                            }
                                            right={
                                                <div className="text-video">
                                                    <div className="showreel-para-left">
                                                        <h7>
                                                            {cmsElmt(
                                                                "Paragraph 1",
                                                                "text",
                                                                "At The Reelists, we have years of video production experience creating promotional materials for causes and organisations we care about. So whether you’re a semi-state institution communicating to the public, a charity raising funds, or a campaign changing Ireland for the better, we can help you."
                                                            )}
                                                        </h7>
                                                        <h7>
                                                            {cmsElmt(
                                                                "Paragraph 2",
                                                                "text",
                                                                "We have a proven ability to take big, important ideas and communicate them to the public in a way that inspires them to take action. With every video, we come up with new and innovative ways of cutting through the noise of social media to make your message stand out."
                                                            )}
                                                        </h7>
                                                    </div>
                                                </div>
                                            }
                                        />
                                    )}
                                </CMSSection>
                                <LogoBlock
                                    logo={client_logos}
                                    logoAlt={`Animation client logos`}
                                />
                                <VideoStack videos={charityVideos} />
                                <TrackVisibility
                                    partialVisibility
                                    style={{ width: "100%" }}
                                >
                                    {({ isVisible }) => (
                                        <Team
                                            isVisible={isVisible}
                                            left={
                                                <div className="who-text-container">
                                                    <h3 data-aos="fade-in">
                                                        So what do we do?
                                                    </h3>
                                                    <div
                                                        className="who-para-container"
                                                        data-aos="fade-in"
                                                    >
                                                        <p>
                                                            <strong>
                                                                Big Ideas
                                                            </strong>{" "}
                                                            <br />
                                                            Your organisation
                                                            has a message for
                                                            the general public.
                                                            Often this is a
                                                            complicated topic, a
                                                            difficult subject,
                                                            or something the
                                                            public simply
                                                            doesn't want to
                                                            hear. We've years of
                                                            experience taking
                                                            complex messages and
                                                            communicating them
                                                            in ways that not
                                                            only make sense, but
                                                            inspire people to
                                                            take action.
                                                        </p>
                                                    </div>
                                                    <div className="who-para-container">
                                                        <p>
                                                            <strong>
                                                                Campaigns
                                                            </strong>
                                                            <br />
                                                            Referendums,
                                                            elections,
                                                            multimedia public
                                                            awareness campaigns.
                                                            We've made hundreds
                                                            of these videos,
                                                            helping to change
                                                            minds and change
                                                            Ireland for the
                                                            better.
                                                        </p>
                                                    </div>
                                                    <div className="who-para-container">
                                                        <p>
                                                            <strong>
                                                                Fundraising
                                                            </strong>
                                                            <br />
                                                            From raising over
                                                            €500,000 in five
                                                            days with Together
                                                            For Yes, to helping
                                                            Maxol convince you
                                                            to part with 10 cent
                                                            for Aware, we've a
                                                            wealth of experience
                                                            creating fundraising
                                                            videos. Whether
                                                            you're after a
                                                            simple 10 second ad,
                                                            or a multi-part
                                                            data-driven
                                                            responsive campaign,
                                                            we've got something
                                                            for you.
                                                        </p>
                                                    </div>
                                                    <div className="who-para-container">
                                                        <p>
                                                            <strong>
                                                                Events
                                                            </strong>
                                                            <br />
                                                            Filming at events is
                                                            a great way to show
                                                            off the good work
                                                            your charity is
                                                            doing. And it helps
                                                            your message reach a
                                                            much wider audience
                                                            than those that
                                                            attended on the day.
                                                        </p>
                                                    </div>
                                                </div>
                                            }
                                            page="Charities"
                                            section="So what do we do"
                                        />
                                    )}
                                </TrackVisibility>
                            </React.Fragment>
                        }
                    />
                )}
            </CMSSection>
        );
    }
}
