import React, { Component } from "react";
import { connect } from "react-redux";
import { DgHelmet } from "dg-utils";

import "../../styles/components/blog/postpage.scss";

// Components
import Header from "../header/header.component";
import Footervideo from "../footervideo/footervideo.component";
import VimeoModal from '../modals/vimeoModal/vimeoModal';

import { getPostById } from "../../actions/post.action";

import seojson from "../../assets/files/seojson.js";

class Post extends Component {
  constructor(props) {
    super(props);

    this.createMarkup = this.createMarkup.bind(this);
  }

  componentDidMount() {
    const pathArr = window.location.pathname.split("/blog/");
    const id = pathArr[1];
    this.props.getPostById(id);
  }

  createMarkup() {
    return {
      __html: this.props.posts.currentPost.post.wysiwyg,
    };
  }

  render() {
    let post = {};

    if (
      this.props.posts &&
      this.props.posts.currentPost &&
      this.props.posts.currentPost.ok
    ) {
      post = this.props.posts.currentPost.post;
    }

    return (
      <div className="post-container">
                <DgHelmet seojson={seojson}></DgHelmet>
        <Header title={
            this.props.posts &&
            this.props.posts.currentPost &&
            this.props.posts.currentPost.ok ? 
            post.title : 
            `Blog`
          } 
        />
        { this.props.posts &&
          this.props.posts.currentPost &&
          this.props.posts.currentPost.ok && (
            <div className="container post">
              <div className="post-main">
                {/* <h6 className="subtitle">{post.summary}</h6> */}
                <div
                  className="post-body"
                  dangerouslySetInnerHTML={this.createMarkup()}
                />
              </div>
            </div>
          )}
        <Footervideo />

        {/* Modals */}
        <VimeoModal />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  posts: state.posts,
});

export default connect(mapStateToProps, { getPostById })(Post);
