// Get Thumbnails from https://depone.dev/video/
export const ourWorkVideos = [
    {
        title: "Island's Edge",
        id: "640406393",
        img: "https://storage.thereelists.com/videoThumbnails/home+page/islands_edge.jpg",
        alt: "Island's Edge",
    },
    {
        title: "Epilepsy Ireland",
        id: "680872067",
        img: "https://storage.thereelists.com/videoThumbnails/Our+Work+Page/Havas_Epilepsy.jpg",
        alt: "Epilepsy Ireland",
    },
    {
        title: "Audi",
        id: "604212655",
        img: "https://storage.thereelists.com/videoThumbnails/Our+Work+Page/Audi.jpg",
        alt: "Audi",
    },
    {
        title: "Marie Keating Foundation | Breast Cancer Isn't Just Pink",
        id: "475598754",
        img: "https://storage.thereelists.com/videoThumbnails/Our+Work+Page/MKF.jpg",
        alt: "Marie Keating Foundation | Breast Cancer Isn't Just Pink",
    },
    {
        title: "Microfinance Ireland | Alpaca Joe",
        id: "604215255",
        img: "https://storage.thereelists.com/videoThumbnails/home+page/MFI_AlpacaJoe.jpg",
        alt: "Microfinance Ireland | Alpaca Joe",
    },
    {
        title: "NWC | Balance The Odds",
        id: "604217426",
        img: "https://storage.thereelists.com/videoThumbnails/home+page/NWC_Boards_Hero.jpg",
        alt: "NWC | Balance The Odds",
    },
    {
        title: "JustEat",
        id: "696503271",
        img: "https://storage.thereelists.com/videoThumbnails/Our+Work+Page/JustEat_Sing_praises.jpg",
        alt: "JustEat",
    },
    {
        title: "Go Visit Donegal | Families",
        id: "641141714",
        img: "https://storage.thereelists.com/videoThumbnails/home+page/Donegal.jpg",
        alt: "Go Visit Donegal",
    },
    {
        title: "We Act",
        id: "656067746",
        img: "https://storage.thereelists.com/videoThumbnails/home+page/We_Act.jpg",
        alt: "JCD | Penrose Dock",
    },
    {
        title: "LADbible",
        id: "656245218",
        img: "https://storage.thereelists.com/videoThumbnails/home+page/LADbible.jpg",
        alt: "LADbible",
    },
    {
        title: "UCD Michael Smurfit Business School",
        id: "764639530",
        img: "https://storage.thereelists.com/videoThumbnails/home+page/UCD.png",
        alt: "UCD Michael Smurfit Business School",
    },
];
