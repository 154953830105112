import React, { Component } from "react";
import { visible } from "../../actions/visible.action";
import { connect } from "react-redux";
import { DgHelmet } from "dg-utils";

import "../../styles/components/ourwork/ourwork.scss";

import Header from "../header/header.component";
import Footervideo from "../footervideo/footervideo.component";
import TrackVisibility from "react-on-screen";

import { ourWorkVideos } from "../../assets/files/vimeolinks";
import VideoStack from './videosStack.component';
import VimeoModal from '../modals/vimeoModal/vimeoModal';

import seojson from "../../assets/files/seojson.js";

class Ourwork extends Component {
    render() {
        return (
            <div className="ourwork">
                <DgHelmet seojson={seojson}></DgHelmet>
                <TrackVisibility partialVisibility style={{ width: "100%" }}>
                    {({ isVisible }) => (
                        <Header isVisible={isVisible} title="Our work" />
                    )}
                </TrackVisibility>
                <VideoStack 
                    videos={ourWorkVideos}
                />
                <TrackVisibility partialVisibility style={{ width: "100%" }}>
                    {({ isVisible }) => <Footervideo isVisible={isVisible} />}
                </TrackVisibility>

                {/* Modals */}
                <VimeoModal />
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    visibility: state.visible,
});

export default connect(mapStateToProps, { visible })(Ourwork);
