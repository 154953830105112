import React, { Component } from "react";
import "../../styles/components/about/text.scss";
import "../../styles/components/about/videobg.scss";
import Button from "../button/button.component";

import { connect } from "react-redux";
import { getVideos } from "../../actions/video.action";
// AOS
import AOS from "aos";

class Videobg extends Component {
    constructor(props) {
        super(props);

        this.state = {
            video: undefined
        }
    }

    componentDidMount() {
        AOS.init();

        this.props.getVideos(
            0, 
            1, 
            this.props.page || 'About', 
            this.props.section ||'Our Videos'
        )
        .then(res => {
            this.setState({
                video: res.videos[0]
            })
        })
        .catch(res => {
            
        });
    }

    render() {
        return (
            <div className="container video-bg">
                {   
                    this.state.video &&
                    this.state.video.files.length > 0 &&
                    this.state.video.files[0].url ? (
                        <video autoPlay playsInline muted loop className="video-bg" style={{zIndex: this.props.isVisible ? "-1" : "-2", position: "fixed"}}>
                            <source
                                src={this.state.video.files[0].url}
                                type="video/mp4"
                            />
                            Sorry, your browser does not support video playback
                        </video>
                    ) : ''
                }
                <div className="videobg-content">
                    <h4 data-aos="fade-in">Our videos have appeared on television, in cinemas, across social media and at the forefront of digital marketing campaigns. So to find out about what The Reelists can do to help you promote the message you care about, contact us today!</h4>

                    <div 
                        className="buttons"
                        data-aos="fade-up"
                    >
                        <Button
                            link="/contact-us"
                            text="Get in touch now"
                        />
                        <Button
                            link="/our-services"
                            text="Our services"
                        />
                        <Button
                            link="/our-work"
                            text="Watch our work"
                        />
                        <Button
                            link="/blog"
                            text="Read our blog"
                        />
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    videos: state.videos,
});

export default connect(mapStateToProps, { getVideos })(Videobg);
