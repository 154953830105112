import React, { Component } from "react";
import AOS from "aos";

// Styles
import "../../styles/components/home/servicesredo.scss";

// Assets
import { getServices } from '../../assets/files/services';

// Components
import Button from "../button/button.component";

export default class Services extends Component {
    componentDidMount() {
        AOS.init();
    }

    render() {
        let renderServices = getServices('black', '').map((service, index) => (
            <div 
                key={`home_page_service_${index}`}
                className="img-element"
            >
                {service.icon}
                <p>{service.name}</p>
            </div>
        ));

        return (
            <div className="container services-home">
                <div className="col-55">
                    <h3 className="what-we-do" data-aos="fade-in">{this.props.title ? this.props.title : ''}</h3>
                    {this.props.content ? this.props.content : ''}
                </div>
                <div className="col-45">
                    <div className="img-container">
                        <div className="images">
                            {renderServices}
                        </div>
                        <Button
                            text="See Our Services"
                            link="/our-services"
                            color="black"
                        />
                        <div className="third"></div>
                    </div>
                </div>
            </div>
        );
    }
}
