import React, { Component } from "react";
import TrackVisibility from "react-on-screen";
import { CMSSection } from "dg-cms";

// Components
import ServiceTemplate from "./services.template";
import Showreelredo from "../home/showreelredo.component";
import LogoBlock from "./logo.block";
import VideoStack from "../ourwork/videosStack.component";
import Team from "../home/team.component";

// Assets
import { propertyVideos } from "../../assets/files/vimeolinks";
import client_logos from "../../assets/service_pages/Logos for Property Page.png";

export default class Property extends Component {
    render() {
        return (
            <CMSSection name="Property Services Page">
                {({ cmsElmt }) => (
                    <ServiceTemplate
                        title={`Property`}
                        page={"Property"}
                        section={"Header"}
                        body={
                            <React.Fragment>
                                <CMSSection name="Property Services">
                                    {({ cmsElmt }) => (
                                        <Showreelredo
                                            left={
                                                <div
                                                    className="showreel-title"
                                                    data-aos="fade-up"
                                                >
                                                    <h3>
                                                        Videos that
                                                        <br />
                                                        sell
                                                        <br />
                                                        properties
                                                        <br />
                                                        and attract
                                                        <br />
                                                        investors
                                                    </h3>
                                                </div>
                                            }
                                            right={
                                                <div className="text-video">
                                                    <div className="showreel-para-left">
                                                        <h7>
                                                            {cmsElmt(
                                                                "Paragraph 1",
                                                                "text",
                                                                "Looking for a video that is designed to showcase your property, attract investors or entice potential buyers? You’ve come to the right place!"
                                                            )}
                                                        </h7>
                                                        <h7>
                                                            {cmsElmt(
                                                                "Paragraph 2",
                                                                "text",
                                                                "Our range of property videos are perfect for real estate agencies, developers and contractors to showcase homes, commercial properties or developments to potential buyers and investors."
                                                            )}
                                                        </h7>
                                                    </div>
                                                    <div className="showreel-vid">
                                                        <iframe
                                                            title="The Reelists Property Showreel"
                                                            src="https://player.vimeo.com/video/414007459?"
                                                            width="640"
                                                            height="360"
                                                            frameborder="0"
                                                            allow="autoplay; fullscreen; picture-in-picture"
                                                            allowfullscreen
                                                        />
                                                    </div>
                                                </div>
                                            }
                                        />
                                    )}
                                </CMSSection>
                                <LogoBlock
                                    logo={client_logos}
                                    logoAlt={`Property client logos`}
                                />
                                <VideoStack videos={propertyVideos} />
                                <TrackVisibility
                                    partialVisibility
                                    style={{ width: "100%" }}
                                >
                                    {({ isVisible }) => (
                                        <Team
                                            isVisible={isVisible}
                                            left={
                                                <div className="who-text-container">
                                                    <h3 data-aos="fade-in">
                                                        So what do we do?
                                                    </h3>
                                                    <div
                                                        className="who-para-container"
                                                        data-aos="fade-in"
                                                    >
                                                        <h7>
                                                            We have a wide range
                                                            of options for your
                                                            production including
                                                            drone, animated maps
                                                            or a virtual
                                                            walkthrough.
                                                        </h7>
                                                    </div>
                                                    <div className="who-para-container">
                                                        <h7>
                                                            <strong>
                                                                New Homes
                                                            </strong>
                                                            <br />
                                                            Our new homes videos
                                                            are perfect for
                                                            showcasing a
                                                            potential buyer
                                                            everything they need
                                                            to know about their
                                                            new home in less
                                                            than two minutes.
                                                            The video functions
                                                            like a moving
                                                            brochure - with
                                                            walkthroughs of the
                                                            house highlighting
                                                            key features, drone
                                                            footage of the
                                                            development, and
                                                            information on local
                                                            amenities and
                                                            commute times.
                                                        </h7>
                                                    </div>
                                                    <div className="who-para-container">
                                                        <h7>
                                                            <strong>
                                                                Investment
                                                            </strong>
                                                            <br />
                                                            Whether you are
                                                            looking to use your
                                                            property video to
                                                            attract investors to
                                                            a proposed
                                                            development or to
                                                            sell existing PRS
                                                            portfolios, our
                                                            cutting-edge
                                                            techniques ensure
                                                            your property stands
                                                            out as a
                                                            best-in-market
                                                            investment
                                                            internationally.
                                                        </h7>
                                                    </div>
                                                    <div className="who-para-container">
                                                        <h7>
                                                            <strong>
                                                                Commercial
                                                            </strong>
                                                            <br />
                                                            Our commercial
                                                            videos are perfect
                                                            for enticing tenants
                                                            to a commercial
                                                            property. We focus
                                                            on showing the
                                                            features and build
                                                            quality of the
                                                            space, its transport
                                                            links and local
                                                            amenities.
                                                        </h7>
                                                    </div>
                                                </div>
                                            }
                                            page="Property"
                                            section="So what do we do"
                                        />
                                    )}
                                </TrackVisibility>
                            </React.Fragment>
                        }
                    />
                )}
            </CMSSection>
        );
    }
}
