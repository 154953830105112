import React, { Component } from "react";
import "../../styles/components/about/text.scss";
// AOS
import AOS from "aos";

export default class Textcomponent extends Component {
    componentDidMount() {
        AOS.init();
    }

    render() {
        return (
            <div className="container text-pink">
                {this.props.content ? this.props.content : (<h5 className="fade-in">{this.props.text}</h5>)}
            </div>
        );
    }
}
