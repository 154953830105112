import React, { Component } from 'react';
import { connect } from 'react-redux';
import swal from 'sweetalert2';
import history from '../../../history';

// Styles
import "../../../styles/components/admin/admin.scss";

// Components
import Sidebar from '../sidebar/sidebar.admin.component';

// Actions
import { uploadImage, AdminEditPost, setPostLoading } from "redux-blogs";
import { updatePost } from '../../../actions/post.action';

class AdminPostEdit extends Component {

    constructor(props) {
        super(props);

        this.onSubmit = this.onSubmit.bind(this);
    }

    componentDidMount() {
        if (
            this.props.posts &&
            this.props.posts.currentPost &&
            Object.keys(this.props.posts.currentPost).length <= 0
        ) {
            history.push(`/admin/posts`);
        }
    }

    onSubmit(data) {
        this.props.setPostLoading(true);

        console.log("Form Submit :: Edit");

        const form = new FormData();
        let body = data;

        if (
            data.header_image &&
            typeof data.header_image !== "string" &&
            data.header_image.length > 0
        ) {
            for (let index = 0; index < data.header_image.length; index++) {
                form.append("updated_header_image", data.header_image[index]);
            }

            delete body.header_image;
        }

        form.append("post_details", JSON.stringify(body));
        console.log(form);
        this.props.updatePost(data._id, form)
        .then(res => {
            swal.fire("Success!", `${res.message}`, "success");
            window.scrollTo(0, 0);
            history.push("/admin/posts");
        })
        .catch(res => {
            swal.fire("Couldn't save Post!", `${res.message}`, "error");
        });
    }

    render() {

        return (
            <div className="admin-container">
                <Sidebar />
                <main>
                    <h1>Admin Edit Post</h1>
                    
                    <AdminEditPost 
                        onSubmit={this.onSubmit}
                        post={this.props.posts.currentPost}
                        uploadImage={this.props.uploadImage}
                        blog={this.props.posts}
                        noPostComponent={(<h2>No Posts</h2>)}
                    />
                </main>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    posts: state.blogs
});

export default connect(mapStateToProps, { 
    updatePost,
    uploadImage,
    setPostLoading,
})(AdminPostEdit);
