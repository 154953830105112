import { 
    FETCH_POSTS, 
    NEW_POST, 
    SELECT_POST, 
    CLEAR_POST_FORM,
    SET_UPLOADS,
    SET_NUM_BLOG_POSTS
} from './types';
import { getAccessToken } from './login.action';

export const setNumBlogPosts = (numPosts) => (dispatch) => {
    return new Promise((resolve) => {
        dispatch({
            type: SET_NUM_BLOG_POSTS,
            payload: numPosts
        });

        resolve(numPosts);
    });
}

export const fetchPosts = () => dispatch => {
    fetch(`${process.env.REACT_APP_API_URL}/api/v1/blog/`)
    .then(res => res.json())
    .then(posts => dispatch({
        type: FETCH_POSTS,
        payload: posts
    }));
};


export const getPostById = id => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
        dispatch({
            type: SELECT_POST,
            payload: true
        });

        fetch(`${process.env.REACT_APP_API_URL}/api/v1/blog/get/${id}`, {
            method: "GET",
            headers: {
                "content-type": "application/json"
            }
        })
            .then(res => res.json())
            .then(post => {
                if (!post.ok) {
                    dispatch({
                        type: SELECT_POST,
                        payload: post
                    });

                    reject(post);
                } else {
                    dispatch({
                        type: SELECT_POST,
                        payload: post
                    });

                    resolve(post);
                }
            });
    });
};

export const selectPost = (postData) => (dispatch) => {
    dispatch({
        type: SELECT_POST,
        payload: postData
    });
}

export const createNewPost = (data) => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
        let token = getAccessToken();
        
        fetch(`${process.env.REACT_APP_API_URL}/api/v1/blog/create`, {
            method: 'POST',
            headers: {
                'access_token': token
            },
            body: data
        })
        .then(res => res.json())
        .then(post => {
            dispatch({
                type: NEW_POST,
                payload: post
            });

            if (!post.ok) {
                reject(post);
            } else {
                resolve(post);
            }
            
        });
    });
};

export const updatePost = (id, data) => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
        let token = getAccessToken();

        fetch(`${process.env.REACT_APP_API_URL}/api/v1/blog/update/${id}`, {
            method: "POST",
            headers: {
                access_token: token
            },
            body: data
        })
            .then(res => res.json())
            .then(post => {
                dispatch({
                    type: NEW_POST,
                    payload: post
                });

                if (!post.ok) {
                    reject(post);
                } else {
                    resolve(post);
                }
            });
    });
};

export const setClearPostForm = (postData) => (dispatch) => {
    dispatch({
        type: CLEAR_POST_FORM,
        payload: postData
    });
}

export const setUploads = (postData) => (dispatch) => {
    dispatch({
        type: SET_UPLOADS,
        payload: postData
    });
}
