import React, { Component } from "react";
import { connect } from "react-redux";
import history from "../../../history";
import Swal from "sweetalert2";

// Styles
import "../../../styles/components/admin/admin.scss";

// Actions
import { uploadVideo } from "../../../actions/video.action";

// Components
import Sidebar from "../sidebar/sidebar.admin.component";
import { VideoForm } from "./video.form";

class CreateVideo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedFile: null,
            page: null,
            section: null,
            id: null,
        };

        this.handleSelectedFile = this.handleSelectedFile.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    componentDidMount() {
        const path = this.cleanPath(this.props.location.pathname);
        const pathArr = path.split("/");
        const location = pathArr[pathArr.length - 1];
        const locationArr = location.split("+");
        this.setState({ 
            page: locationArr[1], 
            section: locationArr[2], 
            id: locationArr[0] 
        });
    }

    cleanPath(path) {
        return path.replace(/%20/g, " ");
    }

    handleSelectedFile(e) {
        e.preventDefault();

        this.setState({
            selectedFile: e.target.files,
        });
    }

    onSubmit(data) {
        const form = new FormData();

        if (this.state.selectedFile && this.state.selectedFile.length > 0) {
            for (
                let index = 0;
                index < this.state.selectedFile.length;
                index++
            ) {
                form.append("files", this.state.selectedFile[index]);
            }
        }
        form.append("title", JSON.stringify(data));

        this.props.uploadVideo(form)
        .then((res) => {
            Swal.fire(`Video Uploaded`, res.message, "success");
            history.push("/admin/videos/");
        })
        .catch((res) => {
            Swal.fire(`Error`, res.message, "error");
        });
    }

    render() {
        return (
            <div className="admin-container">
                <Sidebar />
                <main>
                    {this.state.page && this.state.section && this.state.id && (
                        <VideoForm
                            onSubmit={this.onSubmit}
                            handleSelectedFile={this.handleSelectedFile}
                            disableAll={this.props.videos.isLoading}
                            vidId={this.state.id}
                            initialValues={{
                                id: this.state.id,
                                page: this.state.page,
                                section: this.state.section,
                            }}
                            page={this.state.page}
                            section={this.state.section}
                        />
                    )}
                </main>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    videos: state.videos,
});

export default connect(mapStateToProps, {
    uploadVideo,
})(CreateVideo);
