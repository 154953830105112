import React, { Component } from "react";
import { connect } from "react-redux";
import Swal from "sweetalert2";

// Styles
import "../../../styles/components/admin/admin.scss";

// Actions
import { getContactById } from "../../../actions/contact.action";

// Components
import Sidebar from "../sidebar/sidebar.admin.component";
import { ContactForm } from "./contact.form";

class ViewContact extends Component {
    constructor(props) {
        super(props);

        this.state = {
            contact: {},
        };
    }

    componentDidMount() {
        let urlParams = window.location.pathname;
        let params = urlParams.split("/");

        if (params.length >= 4) {
            this.props
                .getContactById(params[3])
                .then((res) => this.setState({ contact: res }))
                .catch((res) => {
                    Swal.fire("Error", res.message, "error");
                });
        }
    }

    render() {
        console.log(this.state.contact);
        return (
            <div className="admin-container">
                <Sidebar />
                    {this.state.contact.contact &&
                <main>
                    <ContactForm
                        onSubmit={() => {}}
                        disableAll={true}
                        initialValues={this.state.contact.contact}
                        enableReinitialize={true}
                    />
                </main>}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    contacts: state.contacts,
});

export default connect(mapStateToProps, {
    getContactById,
})(ViewContact);
