// What we do icons
import wwd1 from '../service_pages/agencies_icons/svg/What We Do/1.svg';
import wwd2 from '../service_pages/agencies_icons/svg/What We Do/2.svg';
import wwd3 from '../service_pages/agencies_icons/svg/What We Do/3.svg';
import wwd4 from '../service_pages/agencies_icons/svg/What We Do/4.svg';
import wwd5 from '../service_pages/agencies_icons/svg/What We Do/5.svg';
import wwd6 from '../service_pages/agencies_icons/svg/What We Do/6.svg';
import wwd7 from '../service_pages/agencies_icons/svg/What We Do/7.svg';
import wwd8 from '../service_pages/agencies_icons/svg/What We Do/8.svg';
import wwd9 from '../service_pages/agencies_icons/svg/What We Do/9.svg';
import wwd10 from '../service_pages/agencies_icons/svg/What We Do/10.svg';
import wwd11 from '../service_pages/agencies_icons/svg/What We Do/11.svg';
import wwd12 from '../service_pages/agencies_icons/svg/What We Do/12.svg';

// What we use icons
import wwu1 from '../service_pages/agencies_icons/svg/What We Use/1.svg';
import wwu2 from '../service_pages/agencies_icons/svg/What We Use/2.svg';
import wwu3 from '../service_pages/agencies_icons/svg/What We Use/3.svg';
import wwu4 from '../service_pages/agencies_icons/svg/What We Use/4.svg';
import wwu5 from '../service_pages/agencies_icons/svg/What We Use/5.svg';
import wwu6 from '../service_pages/agencies_icons/svg/What We Use/6.svg';
import wwu7 from '../service_pages/agencies_icons/svg/What We Use/7.svg';
import wwu8 from '../service_pages/agencies_icons/svg/What We Use/8.svg';
import wwu9 from '../service_pages/agencies_icons/svg/What We Use/8.svg';
import wwu10 from '../service_pages/agencies_icons/svg/What We Use/9.svg';
import wwu11 from '../service_pages/agencies_icons/svg/What We Use/10.svg';
import wwu12 from '../service_pages/agencies_icons/svg/What We Use/11.svg';

export const what_we_do = [
    {
        name: 'Full Service Production',
        icon: wwd1
    },
    {
        name: 'Shooting',
        icon: wwd2
    },
    {
        name: 'Editing & Post Production',
        icon: wwd3
    },
    {
        name: 'Case Studies',
        icon: wwd4
    },
    {
        name: 'Activation Videos',
        icon: wwd5
    },
    {
        name: 'In-Agency Editing',
        icon: wwd6
    },
    {
        name: 'Drone Videography',
        icon: wwd7
    },
    {
        name: 'Motion Graphics',
        icon: wwd8
    },
    {
        name: 'Freelance Crewing',
        icon: wwd9
    },
    {
        name: 'Animation',
        icon: wwd10
    },
    {
        name: 'On-Site Same-Day Video',
        icon: wwd11
    },
    {
        name: 'Green Screen',
        icon: wwd12
    }
];

export const what_we_use = [
    {
        name: 'Sony FS7ii',
        icon: wwu1
    },
    {
        name: 'Sony Fx6',
        icon: wwu2
    },
    {
        name: 'Sony A7sii',
        icon: wwu3
    },
    {
        name: 'Mavic 2 Pro Drone',
        icon: wwu4
    },
    {
        name: 'Zhiyun Crane 3s Pro Gimbal',
        icon: wwu5
    },
    {
        name: 'Full Sound Kit',
        icon: wwu6
    },
    {
        name: 'Full LED Lighting Kit',
        icon: wwu7
    },
    {
        name: 'Remote Control',
        icon: wwu8
    },
    {
        name: 'Teleprompter',
        icon: wwu9
    },
    {
        name: 'Premiere Pro',
        icon: wwu10
    },
    {
        name: 'After Effects',
        icon: wwu11
    },
    {
        name: 'Adobe Creative Cloud',
        icon: wwu12
    }
];