import React, { Component } from "react";
import { connect } from "react-redux";
import { DgHelmet } from "dg-utils";

import Header from "../header/header.component";
import Footervideo from "../footervideo/footervideo.component";
import VimeoModal from '../modals/vimeoModal/vimeoModal';

import Bloglist from "./blogList.component";
import TrackVisibility from "react-on-screen";

import seojson from "../../assets/files/seojson.js";

//Assets

// Actions

//Styles
import "../../styles/components/blog/blogLatest.scss";

class Blog extends Component {

    render() {
        return (
            <div className="blogLatest-container">
                <DgHelmet seojson={seojson}></DgHelmet>
                <TrackVisibility partialVisibility style={{ width: "100%" }}>
                    {({ isVisible }) => (
                        <Header isVisible={isVisible} title="Blog" />
                    )}
                </TrackVisibility>
                <Bloglist  />
                <TrackVisibility partialVisibility style={{ width: "100%" }}>
                    {({ isVisible }) => <Footervideo isVisible={isVisible} />}
                </TrackVisibility>

                {/* Modals */}
                <VimeoModal />
            </div>
        );
    }
}

export default connect(null, {  })(Blog);
