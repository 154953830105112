import React, { Component } from "react";
import { connect } from "react-redux";
import AOS from "aos";
import { CMSSection } from "dg-cms";
// Actions
import { getVideos } from "../../actions/video.action";

// Styles
import "../../styles/components/home/teamredo.scss";

// Components
import ButtonAlt from "../button/buttonAlt.component";

class Team extends Component {
    constructor(props) {
        super(props);

        this.state = {
            video: undefined,
        };
    }

    componentDidMount() {
        AOS.init();

        this.props
            .getVideos(
                0,
                1,
                this.props.page || "Home",
                this.props.section || "Who are we anyway"
            )
            .then((res) => {
                this.setState({
                    video: res.videos[0],
                });
            })
            .catch((res) => {});
    }

    render() {
        return (
            <CMSSection name="Who are We">
                {({ cmsElmt }) => (
                    <div
                        className="container team-home"
                        style={
                            this.props.isVisible
                                ? { backgroundImage: "unset" }
                                : {}
                        }
                    >
                        {this.state.video &&
                        this.state.video.files.length > 0 &&
                        this.state.video.files[0].url ? (
                            <video
                                autoPlay
                                playsInline
                                muted
                                loop
                                className="video-bg"
                                style={{
                                    zIndex: this.props.isVisible ? "-1" : "-2",
                                    position: "fixed",
                                }}
                            >
                                <source
                                    src={this.state.video.files[0].url}
                                    type="video/mp4"
                                />
                                Sorry, your browser does not support video
                                playback
                            </video>
                        ) : (
                            ""
                        )}
                        <div className="col-65">
                            {this.props.left ? (
                                this.props.left
                            ) : (
                                <div className="who-text-container">
                                    <h3 data-aos="fade-in">
                                        {cmsElmt(
                                            "Who are we Header",
                                            "text",
                                            "Who are we, anyway?"
                                        )}
                                    </h3>
                                    <div className="who-para-container">
                                        <h7 data-aos="fade-in">
                                            {cmsElmt(
                                                "Who are we paragraph",
                                                "text",
                                                "The Reelists are a commercial video production company based in Dublin and working all over Ireland. Our videos have appeared on television, in cinemas, across social media and at the forefront of digital marketing campaigns. We have worked with all kinds of people - multinationals, advertising agencies, SMEs, local businesses, charities, friends, rock bands and more. We do everything - from corporate video production to music videos and marketing videos. Though our projects and clientele vary hugely, our mission remains the same: to produce beautiful videos for happy clients."
                                            )}
                                        </h7>
                                    </div>
                                    <div className="who-button-container">
                                        <ButtonAlt
                                            link="/about-the-reelists"
                                            text="Read All About Us"
                                            aosEffect=""
                                            aosDelay=""
                                        />
                                        {/*
                                        <ButtonAlt
                                            link="/blog"
                                            text="Read our blog"
                                            aosEffect=""
                                            aosDelay=""
                                        />
                                        */}
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="col-35"></div>
                    </div>
                )}
            </CMSSection>
        );
    }
}

const mapStateToProps = (state) => ({
    videos: state.videos,
});

export default connect(mapStateToProps, { getVideos })(Team);
