import React from "react";
import "./styles/App.scss";
import { Provider } from "react-redux";
import { Switch, Route, Router } from "react-router-dom";
import { store } from "./store";
import history from "./history";

import "redux-blogs/build/esm/index.css";
import "dg-forms/dist/index.js";
import "dg-utils/build/esm/index.css";
//Imported to fix styling, but dg-admin component not used in project
import "dg-admin/dist/index.css";

// Components
import Home from "./components/home/home.component";
import About from "./components/about/about.component";
import Ourwork from "./components/ourwork/ourwork.component";
import Ourservices from "./components/ourservices/ourservices.component";
import Contact from "./components/contact/contact.component";
// import Whoweare from "./components/whoweare/whoweare.component";
import Videosforyourbusiness from "./components/videosforyourbusiness/videosforyourbusiness.component";

// Service Pages
import Animation from "./components/services/animation.component";
import Production from "./components/services/production.component";
import Property from "./components/services/property.component";
import FullService from "./components/services/full_service.component";
import Videography from "./components/services/videography.component";
import Drone from "./components/services/drone.component";
import Charities from "./components/services/charites.component";

// Blog components
import ProtectedRoute from "./components/protectedRoute.component";
import Login from "./components/login/login.component";

// User Facing Blog
import Blog from "./components/blog/blogLatest.component";
import Post from "./components/blog/post.component";

// Protected Routes

// Admin - Blogs
import AdminPostList from "./components/admin/blogs/adminPostList.component";
import AdminPostNew from "./components/admin/blogs/adminNewPost.component";
import AdminPostEdit from "./components/admin/blogs/adminEditPost.component";

// Admin - Contacts
import ContactList from "./components/admin/contact/list.component";
import ViewContact from "./components/admin/contact/view.component";

// Admin - Bookings
import BookingList from "./components/admin/booking/list.component";
import ViewBooking from "./components/admin/booking/view.component";

//Admin - Videos
import VideoList from "./components/admin/videos/listVideos.component";
import CreateVideo from "./components/admin/videos/createVideo.component";

// Admin - CMS
import CMSList from "./components/admin/cms/cmsList";
import EditCMS from "./components/admin/cms/editCMS";

function App() {
    return (
        <Provider store={store}>
            <Router history={history}>
                <Switch>
                    <Route path="/" exact component={Home} />
                    <Route path="/about-the-reelists" exact component={About} />
                    <Route path="/our-work" exact component={Ourwork} />
                    <Route path="/our-services" exact component={Ourservices} />
                    <Route path="/contact-us" exact component={Contact} />
                    {/* <Route path="/who-we-are" exact component={Whoweare} /> */}
                    <Route
                        path="/videos-for-your-business"
                        exact
                        component={Videosforyourbusiness}
                    />
                    <Route path="/animation" exact component={Animation} />
                    <Route
                        path="/production-services"
                        exact
                        component={Production}
                    />
                    <Route path="/property-videos" exact component={Property} />
                    <Route
                        path="/full-service-production"
                        exact
                        component={FullService}
                    />
                    <Route path="/videography" exact component={Videography} />
                    <Route path="/drone" exact component={Drone} />
                    <Route path="/publiccomms" exact component={Charities} />
                    <Route path="/admin" exact component={Login} />
                    {/*
                        <Route path="/blog" exact component={Blog} />
                        <Route path="/blog/:id" exact component={Post} />
                    */}
                    <ProtectedRoute
                        path="/admin/posts"
                        exact
                        component={AdminPostList}
                    />
                    <ProtectedRoute
                        path="/admin/posts/create"
                        exact
                        component={AdminPostNew}
                    />
                    <ProtectedRoute
                        path="/admin/post/:id"
                        exact
                        component={AdminPostEdit}
                    />
                    <ProtectedRoute
                        path="/admin/contacts"
                        exact
                        component={ContactList}
                    />
                    <ProtectedRoute
                        path="/admin/contact/:id"
                        exact
                        component={ViewContact}
                    />
                    <ProtectedRoute
                        path="/admin/bookings"
                        exact
                        component={BookingList}
                    />
                    <ProtectedRoute
                        path="/admin/booking/:id"
                        exact
                        component={ViewBooking}
                    />
                    <ProtectedRoute
                        path="/admin/videos"
                        exact
                        component={VideoList}
                    />
                    <ProtectedRoute
                        path="/admin/videos/create/:id"
                        exact
                        component={CreateVideo}
                    />
                    <ProtectedRoute
                        path="/admin/cms"
                        exact
                        component={CMSList}
                    />
                    <ProtectedRoute
                        path="/admin/cms/:id"
                        exact
                        component={EditCMS}
                    />
                </Switch>
            </Router>
        </Provider>
    );
}

export default App;
