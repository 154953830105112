import { 
    CONTACT_SUBMIT,
    GET_CONTACTS
} from "./types";

export const createContact = (data) => (dispatch, getState) => {
    return new Promise((resolve, reject) => {

        fetch(`${process.env.REACT_APP_API_URL}/api/v1/contacts/create`, {
            method: "POST",
            headers: {
                "content-type": "application/json"
            },
            body: JSON.stringify(data)
        })
            .then(res => res.json())
            .then(post => {
                dispatch({
                    type: CONTACT_SUBMIT,
                    payload: post
                });

                if (!post.ok) {
                    reject(post);
                } else {
                    resolve(post);
                }
            });
    });
};

export const getContacts = () => (dispatch, getState) => {
    return new Promise((resolve, reject) => {

        fetch(`${process.env.REACT_APP_API_URL}/api/v1/contacts/`, {
            method: "GET",
            headers: {
                "content-type": "application/json"
            }
        })
            .then(res => res.json())
            .then(post => {
                dispatch({
                    type: GET_CONTACTS,
                    payload: post
                });
                
                if (!post.ok) {
                    reject(post);
                } else {
                    resolve(post);
                }
            });
    });
};

export const getContactById = (contactId) => (dispatch, getState) => {
    return new Promise((resolve, reject) => {

        fetch(`${process.env.REACT_APP_API_URL}/api/v1/contacts/get/${contactId}`, {
            method: "GET",
            headers: {
                "content-type": "application/json"
            }
        })
            .then(res => res.json())
            .then(post => {
                dispatch({
                    type: CONTACT_SUBMIT,
                    payload: post
                });
                
                if (!post.ok) {
                    reject(post);
                } else {
                    resolve(post);
                }
            });
    });
};
