import { 
    SET_VIDEO_MODAL
} from '../actions/types';

const initialState = {
    isVideoModalShowing: false,
    url: ''
};

export default function (state = initialState, action) {
    switch(action.type) {
        case SET_VIDEO_MODAL:
            return {
                ...state,
                isVideoModalShowing: action.payload.showModal,
                url: action.payload.url
            }
        default: 
            return state;
    }
}