import React, { Component } from "react";

// AOS
import AOS from "aos";

import GMaps from "./gmaps.component";

import "../../styles/components/contact/contactmap.scss";

// Assets

class Contactmap extends Component {
    componentDidMount() {
        AOS.init();
    }
    render() {
        return (
            <div className="container contact-map">
                <div className="col-40">
                    {/*
                    <div className="contact-line">
                        <h4 style={{marginLeft: 'unset'}}>Call</h4>
                        <h6>01-2444185</h6>
                    </div>
                    */}
                    <a 
                        href="mailto:info@thereelists.com" 
                        className="contact-line"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <h4>Email</h4>
                        <h6>info@thereelists.com</h6>
                            </a>
                    {/*
                    <div className="contact-line address">
                        <h4>Address </h4>
                        <h6>
                            {" "}
                            9 Baggot Street Upper, <br />
                            Dublin 4
                        </h6>
                    </div>
                    */}
                </div>
                <div className="col-60">
                    <GMaps
                        isMarkerShown
                        googleMapURL="https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=AIzaSyDxizgvGaZ6ndU2h1sHCOsx9-jXtLFC69Q"
                        loadingElement={<div style={{ height: `100%` }} />}
                        containerElement={
                            <div style={{ height: `400px`, width: `100%` }} />
                        }
                        mapElement={
                            <div style={{ height: `400px`, width: `100%` }} />
                        }
                    />
                </div>
            </div>
        );
    }
}

export default Contactmap;
