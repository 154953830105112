import React, { Component } from "react";
import TrackVisibility from "react-on-screen";
import { CMSSection } from "dg-cms";

// Components
import ServiceTemplate from "./services.template";
import Showreelredo from "../home/showreelredo.component";
import LogoBlock from "./logo.block";
import VideoStack from "../ourwork/videosStack.component";
import Team from "../home/team.component";

// Assets
import { animationVideos } from "../../assets/files/vimeolinks";
import client_logos from "../../assets/service_pages/Logos for Animation and Drone Page.png";

export default class Animation extends Component {
    render() {
        return (
            <CMSSection name="Animation Page">
                {({ cmsElmt }) => (
                    <ServiceTemplate
                        title={`Animation`}
                        page={"Animation"}
                        section={"Header"}
                        body={
                            <React.Fragment>
                                <CMSSection name="Services Animation">
                                    {({ cmsElmt }) => (
                                        <Showreelredo
                                            left={
                                                <div
                                                    className="showreel-title"
                                                    data-aos="fade-up"
                                                >
                                                    <h2>
                                                        Animated videos that
                                                        <br />
                                                        are fast,
                                                        <br />
                                                        effective and
                                                        <br />
                                                        affordable
                                                    </h2>
                                                </div>
                                            }
                                            right={
                                                <div className="text-video">
                                                    <div className="showreel-para-left">
                                                        <h7>
                                                            {cmsElmt(
                                                                "Paragraph 1",
                                                                "text",
                                                                "We know animation is a great way to tell your story, sell your product or promote your services in a way that’s eye-catching and fun. Our video animation services promise a lightning-fast turnaround with results that are guaranteed to be engaging and on-brand."
                                                            )}
                                                        </h7>
                                                        <h7>
                                                            {cmsElmt(
                                                                "Paragraph 2",
                                                                "text",
                                                                "Why should you choose The Reelists for your animated video? We do everything, including animated social media ads, infographics and animated explainers. And we can work in any style you like: 2D or 3D, hand drawn, computer generated, animated maps, product renders and previs, you name it! Our stellar video production team will work with you to determine what kind of animated video best suits your needs. Our services are fast, effective and affordable, creating a video that will make your brand stand out from the crowd."
                                                            )}
                                                        </h7>
                                                    </div>
                                                    <div className="showreel-vid">
                                                        <iframe
                                                            title="The Reelists Animation Showreel"
                                                            src="https://player.vimeo.com/video/403704022?"
                                                            width="640"
                                                            height="360"
                                                            frameborder="0"
                                                            allow="autoplay; fullscreen; picture-in-picture"
                                                            allowfullscreen
                                                        />
                                                    </div>
                                                </div>
                                            }
                                        />
                                    )}
                                </CMSSection>
                                <LogoBlock
                                    logo={client_logos}
                                    logoAlt={`Animation client logos`}
                                />
                                <VideoStack
                                    videos={animationVideos}
                                    showSeeMore={false}
                                />
                                <TrackVisibility
                                    partialVisibility
                                    style={{ width: "100%" }}
                                >
                                    {({ isVisible }) => (
                                        <Team
                                            isVisible={isVisible}
                                            left={
                                                <div className="who-text-container">
                                                    <h3 data-aos="fade-in">
                                                        So how does it work?
                                                    </h3>
                                                    <div
                                                        className="who-para-container"
                                                        data-aos="fade-in"
                                                    >
                                                        <h7>
                                                            We look after every
                                                            aspect, from concept
                                                            development all the
                                                            way through to
                                                            voiceover recording
                                                            and with our
                                                            dedicated animation
                                                            specialists, you're
                                                            guaranteed the
                                                            highest quality
                                                            product, with the
                                                            fastest turnaround.
                                                        </h7>
                                                    </div>
                                                    <div className="who-para-container">
                                                        <h7>
                                                            <strong>
                                                                Develop.
                                                            </strong>{" "}
                                                            We start with a
                                                            consultation to
                                                            develop the concept.
                                                            Then, we write the
                                                            script and create
                                                            storyboards to show
                                                            you what it'll all
                                                            look like.
                                                        </h7>
                                                    </div>
                                                    <div className="who-para-container">
                                                        <h7>
                                                            <strong>
                                                                Create.
                                                            </strong>{" "}
                                                            We create the
                                                            artwork and animate
                                                            it. Then we record a
                                                            professional
                                                            voiceover and add
                                                            music and sound
                                                            effects to bring it
                                                            to life.
                                                        </h7>
                                                    </div>
                                                    <div className="who-para-container">
                                                        <h7>
                                                            <strong>
                                                                Customise.
                                                            </strong>{" "}
                                                            Finally, we
                                                            understand every
                                                            social platform is
                                                            different. We
                                                            customise your video
                                                            so that your message
                                                            is crystal clear, no
                                                            matter where it's
                                                            being watched.
                                                        </h7>
                                                    </div>
                                                </div>
                                            }
                                            page="Animation"
                                            section="So how does it work"
                                        />
                                    )}
                                </TrackVisibility>
                            </React.Fragment>
                        }
                    />
                )}
            </CMSSection>
        );
    }
}
