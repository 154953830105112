const seojson = {
    meta_descriptions: [
        {
            url: "/",
            content:
                "We provide video production that reaches the right audience and your business goals. High-quality videos, from one of Ireland’s most respected video companies.",
        },
        {
            url: "/our-services",
            content:
                "We handle every step of the video production process, from concept development to editing. Our services include animation, drone footage, social media and more.",
        },
        {
            url: "/our-work",
            content:
                "We have created videos for some of the biggest companies in Ireland. Book a free consultation with us today and we’ll help to get the process started. ",
        },
        {
            url: "/about-the-reelists",
            content:
                "We are one of Ireland’s most respected video production companies. Our videos have appeared across tv, cinemas, social media and more.",
        },
        {
            url: "/contact-us",
            content:
                "Are you looking for the best in video production services in Ireland? Look no further. Contact us today to have a free consultation.",
        },
        {
            url: "/blog",
            content:
                "Our blog includes information on our video production services and case studies of some leading brands we’ve partnered creatively with.",
        },
        {
            url: "/property-videos",
            content:
                "We specialise in property showcasing videos to attract potential buyers and sell your property. Contact us today and showcase your property through video.",
        },
        {
            url: "/animation",
            content:
                "We produce animated videos that are fast, effective and affordable. Bring your brand to life with an animated video and contact us for a free consultation.",
        },
        {
            url: "/production-services",
            content:
                "We deliver video production on time, on budget and above expectations. Whatever the video production need, rest assured that We Do Everything.",
        },
        {
            url: "/drone",
            content:
                "We operate state-of-the-art drones that can capture 4k aerial video, hyper lapses and high-quality aerial photos. Let our drone showreel show you what we can do.",
        },
        {
            url: "https://thereelists.com/charities",
            content:
                "We have years of experience creating videos for charitable causes and organisations. We create videos that raise awareness and influence public opinion.",
        },
    ],
    meta_titles: [
        {
            url: "/",
            content: " The Reelists - Video Production Experts Ireland ",
        },
        {
            url: "/our-services",
            content: " The Reelists - Our Services ",
        },
        {
            url: "/our-work",
            content: " The Reelists - Our Video Production Work ",
        },
        {
            url: "/contact-us",
            content: " The Reelists - Contact Us ",
        },
        {
            url: "/about-the-reelists",
            content: " The Reelists - About Us ",
        },
        {
            url: "/publiccomms",
            content: " The Reelists - Public Communications ",
        },
        {
            url: "/blog",
            content: " The Reelists - Our Blog ",
        },
        {
            url: "/animation",
            content: " The Reelists - Animation ",
        },
        {
            url: "/property-videos",
            content:
                " The Reelists - Property ",
        },
        {
            url: "/production-services",
            content: " The Reelists - Agencies ",
        },
        {
            url: "/drone",
            content: " The Reelists - Drone ",
        },
        {
            url: "https://thereelists.com/charities",
            content: " The Reelists - Charities ",
        },
    ],
};

export default seojson;
