// Jpeg
import img1 from "../studio/1-min.jpg";
import img2 from "../studio/2-min.jpg";
import img3 from "../studio/3-min.jpg";
import img4 from "../studio/4-min.jpg";
import img5 from "../studio/5-min.jpg";
import img6 from "../studio/6-min.jpg";
import img7 from "../studio/7-min.jpg";
import img8 from "../studio/8-min.jpg";
import img9 from "../studio/9-min.jpg";
import img10 from "../studio/10-min.jpg";
import img11 from "../studio/11-min.jpg";
import img12 from "../studio/12-min.jpg";
import img13 from "../studio/13-min.jpg";
import img14 from "../studio/14-min.jpg";
import img15 from "../studio/15-min.jpg";
import img16 from "../studio/16-min.jpg";
import img17 from "../studio/17-min.jpg";
import img18 from "../studio/18-min.jpg";
import img19 from "../studio/19-min.jpg";
import img20 from "../studio/20-min.jpg";
import img21 from "../studio/21-min.jpg";
import img22 from "../studio/22-min.jpg";
import img23 from "../studio/23-min.jpg";
import img24 from "../studio/24-min.jpg";
import img25 from "../studio/25-min.jpg";
import img26 from "../studio/26-min.jpg";
import img27 from "../studio/27-min.jpg";
import img28 from "../studio/28-min.jpg";

// Webp
import img1_webp from "../studio/1-min.jpg.webp";
import img2_webp from "../studio/2-min.jpg.webp";
import img3_webp from "../studio/3-min.jpg.webp";
import img4_webp from "../studio/4-min.jpg.webp";
import img5_webp from "../studio/5-min.jpg.webp";
import img6_webp from "../studio/6-min.jpg.webp";
import img7_webp from "../studio/7-min.jpg.webp";
import img8_webp from "../studio/8-min.jpg.webp";
import img9_webp from "../studio/9-min.jpg.webp";
import img10_webp from "../studio/10-min.jpg.webp";
import img11_webp from "../studio/11-min.jpg.webp";
import img12_webp from "../studio/12-min.jpg.webp";
import img13_webp from "../studio/13-min.jpg.webp";
import img14_webp from "../studio/14-min.jpg.webp";
import img15_webp from "../studio/15-min.jpg.webp";
import img16_webp from "../studio/16-min.jpg.webp";
import img17_webp from "../studio/17-min.jpg.webp";
import img18_webp from "../studio/18-min.jpg.webp";
import img19_webp from "../studio/19-min.jpg.webp";
import img20_webp from "../studio/20-min.jpg.webp";
import img21_webp from "../studio/21-min.jpg.webp";
import img22_webp from "../studio/22-min.jpg.webp";
import img23_webp from "../studio/23-min.jpg.webp";
import img24_webp from "../studio/24-min.jpg.webp";
import img25_webp from "../studio/25-min.jpg.webp";
import img26_webp from "../studio/26-min.jpg.webp";
import img27_webp from "../studio/27-min.jpg.webp";
import img28_webp from "../studio/28-min.jpg.webp";

export const studioImages = [
    {
        img: img1,
        webp: img1_webp
    },
    {
        img: img2,
        webp: img2_webp
    },
    {
        img: img3,
        webp: img3_webp
    },
    {
        img: img4,
        webp: img4_webp
    },
    {
        img: img5,
        webp: img5_webp
    },
    {
        img: img6,
        webp: img6_webp
    },
    {
        img: img7,
        webp: img7_webp
    },
    {
        img: img8,
        webp: img8_webp
    },
    {
        img: img9,
        webp: img9_webp
    },
    {
        img: img10,
        webp: img10_webp
    },
    {
        img: img11,
        webp: img11_webp
    },
    {
        img: img12,
        webp: img12_webp
    },
    {
        img: img13,
        webp: img13_webp
    },
    {
        img: img14,
        webp: img14_webp
    },
    {
        img: img15,
        webp: img15_webp
    },
    {
        img: img16,
        webp: img16_webp
    },
    {
        img: img17,
        webp: img17_webp
    },
    {
        img: img18,
        webp: img18_webp
    },
    {
        img: img19,
        webp: img19_webp
    },
    {
        img: img20,
        webp: img20_webp
    },
    {
        img: img21,
        webp: img21_webp
    },
    {
        img: img22,
        webp: img22_webp
    },
    {
        img: img23,
        webp: img23_webp
    },
    {
        img: img24,
        webp: img24_webp
    },
    {
        img: img25,
        webp: img25_webp
    },
    {
        img: img26,
        webp: img26_webp
    },
    {
        img: img27,
        webp: img27_webp
    },
    {
        img: img28,
        webp: img28_webp
    }
];