import React, { Component } from "react";
import AOS from "aos";
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from "react-accessible-accordion";

// Styles
import "../../styles/components/whoweare/team.scss";
import "react-accessible-accordion/dist/fancy-example.css";

// Assets
import { team } from "../../assets/files/team";

export default class Team extends Component {
    constructor(props) {
        super(props);

        this.state = { img: team[0].img };

        this.onTeamChange = this.onTeamChange.bind(this);
    }
    componentDidMount() {
        AOS.init();
    }

    onTeamChange = (uuid) => {
        const index = parseInt(uuid[0].substring(uuid[0].length - 1));
        this.setState({ img: team[index].img });
    };

    render() {
        const teamList = team.map((item, index) => {
            return (
                <AccordionItem uuid={`team_${index}`}>
                    <AccordionItemHeading>
                        <AccordionItemButton 
                            data-aos="fade-in" 
                            data-aos-offset="0" 
                            data-aos-duration="800"
                        >
                            {item.name} - {item.position}
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p>{item.description}</p>
                    </AccordionItemPanel>
                </AccordionItem>
            );
        });
        return (
            <div className="container team-main">
                <div
                    className="col-50 photo-container"
                    style={{
                        backgroundImage: `url(${this.state.img})`,
                        backgroundSize: "cover",
                    }}
                ></div>
                <div className="col-50 team-names">
                    <Accordion preExpanded={['team_0']} onChange={this.onTeamChange}>
                        {teamList}
                    </Accordion>
                </div>
            </div>
        );
    }
}
