import React, { Component } from "react";

import { connect } from "react-redux";
import { Field, reduxForm, reset } from "redux-form";

import "../../styles/components/contact/enquiryform.scss";
// AOS
import AOS from "aos";

const afterSubmit = (result, dispatch) => dispatch(reset("Enquiryform"));
const required = value => value ? undefined : 'Required';
const email = value => value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ? 'Invalid email address' : undefined;

class Enquiryform extends Component {

    componentDidMount() {
        AOS.init();
    }

    render() {
        const { handleSubmit, onSubmit } = this.props;

        return (
            <div className="container enquiry">
                <div className="col-60" data-aos="fade-in">
                    <h3>
                        {" "}
                        Email us at <br /> info@thereelists.com
                    </h3>
                </div>
                <div
                    className="col-40"
                    data-aos="fade-in"
                >
                    <h4> Make an enquiry</h4>
                    <div className="form-container">
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <Field
                                className="field-input"
                                name="name"
                                component="input"
                                type="text"
                                placeholder="Name"
                                validate={[required]}
                            />
                            <Field
                                className="field-input"
                                name="email"
                                component="input"
                                type="text"
                                placeholder="Email Address"
                                validate={[required, email]}
                            />
                            <Field
                                className="field-input"
                                name="subject"
                                component="input"
                                type="text"
                                placeholder="Subject"
                                validate={[required]}
                            />
                            <Field
                                className="field-input"
                                name="message"
                                component="textarea"
                                type="text"
                                rows="1"
                                placeholder="Message"
                                validate={[required]}
                            />
                            <button className="book-now" type="submit">
                                <span className="un">
                                    Send Message
                                </span>
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

Enquiryform = reduxForm({ form: "Enquiryform", onSubmitSuccess: afterSubmit })(
    Enquiryform
);

export default connect(null, {})(Enquiryform);
