export const FETCH_POSTS = 'FETCH_POSTS';
export const NEW_POST = 'NEW_POST';
export const SELECT_POST = 'SELECT_POST';
export const CLEAR_POST_FORM = 'CLEAR_POST_FORM';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILED = 'LOGIN_FAILED';
export const LOGOUT = 'LOGOUT';
export const VISIBLE = 'VISIBLE';
export const MOBILENAV = 'MOBILENAV';
export const SET_UPLOADS = 'SET_UPLOADS';
export const CONTACT_SUBMIT_SUCCESS = 'CONTACT_SUBMIT_SUCCESS';
export const CONTACT_SUBMIT_FAILED = 'CONTACT_SUBMIT_FAILED';
export const GET_CONTACTS = 'GET_CONTACTS';
export const CONTACT_SUBMIT = 'CONTACT_SUBMIT';
export const SET_LOCATION = 'SET_LOCATION';
export const SET_KEYWORD = 'SET_KEYWORD';
export const BOOKING_SUBMIT_SUCCESS = 'BOOKING_SUBMIT_SUCCESS';
export const BOOKING_SUBMIT_FAILED = 'BOOKING_SUBMIT_FAILED';
export const GET_BOOKINGS = 'GET_BOOKINGS';
export const BOOKING_SUBMIT = 'BOOKING_SUBMIT';
export const UPLOAD_VIDEO = 'UPLOAD_VIDEO';
export const GET_VIDEOS = 'GET_VIDEOS';
export const SET_IS_LOADING_VIDEO = 'SET_IS_LOADING_VIDEO';
export const SET_VIDEO_MODAL = 'SET_VIDEO_MODAL';
export const SET_NUM_BLOG_POSTS = 'SET_NUM_BLOG_POSTS';