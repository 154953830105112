// import React from "react";


export const testimonials = [
    {
        name: `Michael Reilly`,
        company: "Disney",
        comments: `“I have worked with a lot of companies over the years and The Reelists really were one of the best when it came to delivering exactly what we were looking for.”`,
        createdAt: "2020-08-27T17:24:40.778Z"
    },
    {
        name: `Teodora Georgieva`,
        company: "Harvey Norman",
        comments: `"It was amazing how quickly they were able to grasp our ideas and to understand our needs."`,
        createdAt: "2020-08-27T17:24:40.778Z"
    },
    {
        name: `Melanie Wright`,
        company: "Dublin City Council Culture Company",
        comments: `“We have worked with The Reelists on a number of video projects. They are incredibly professional and always deliver high quality video content. They are responsive to our needs and always go out of their way to accommodate what we need. A pleasure to work with every time.”`,
        createdAt: "2020-08-27T17:24:40.778Z"
    },
    {
        name: `Peter Tanham`,
        company: "Social Democrats & Together For Yes",
        comments: `“The Reelists produced videos that helped generate over €600,000 in crowdfunded donations. They took ownership of the project to ensure a timely, flawless delivery.”`,
        createdAt: "2020-08-27T17:24:40.778Z"
    },
    {
        name: `Fiachra Garvey`,
        company: "West Wicklow Festival",
        comments: `"They were friendly and professional, rare to find such a combination!"`,
        createdAt: "2020-08-27T17:24:40.778Z"
    },
    {
        name: `Deirdre Parkinson`,
        company: "Microfinance Ireland",
        comments: `"The guys are very enthusiastic and a pleasure to deal with. They’ll be a go-to partner on future projects.”`,
        createdAt: "2020-08-27T17:24:40.778Z"
    },
    {
        name: `Ceri Dixon`,
        company: "Rubyworks Records",
        comments: `"From start to finish, they were communicative and made sure we were happy with every step of the process."`,
        createdAt: "2020-08-27T17:24:40.778Z"
    }
];
