import React, { Component } from "react";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import history from "../../../history";

// Styles
import "../../../styles/components/admin/list.scss";
import "../../../styles/components/admin/admin.scss";

// Actions
import { getBookings } from "../../../actions/booking.action";

// Components
import Sidebar from "../sidebar/sidebar.admin.component";

class BookingList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            bookings: {},
        };
    }

    async componentDidMount() {
        await this.props
            .getBookings()
            .then((res) => this.setState({ bookings: res }))
            .catch((res) => {
                Swal.fire(`Error`, res.message, "error");
            });
    }

    render() {
        let rows = [];

        if (
            this.state.bookings.bookings &&
            this.state.bookings.bookings.length > 0
        ) {
            rows = this.state.bookings.bookings.map((value, index) => (
                <tr onClick={() => history.push(`/admin/booking/${value._id}`)}>
                    <td>{value.your_details.name}</td>
                    <td>{value.your_details.subject}</td>
                    <td>{value.your_details.number}</td>
                    <td>{value.your_details.email}</td>
                    <td>{value.your_details.date}</td>
                    <td>{value.your_details.time}</td>
                    <td>
                        {new Date(value.createdAt).toLocaleDateString()}{" "}
                        {new Date(value.createdAt).toLocaleTimeString()}
                    </td>
                </tr>
            ));
        } else {
            rows = [
                <tr>
                    <td colSpan="5">No Bookings available ...</td>
                </tr>,
            ];
        }

        return (
            <div className="admin-container">
                <Sidebar />
                <main>
                    <div className="List-Container">
                        <div className="list">
                            <table>
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Subject</th>
                                        <th>Number</th>
                                        <th>Email</th>
                                        <th>Date</th>
                                        <th>Time</th>
                                    </tr>
                                </thead>
                                <tbody>{rows}</tbody>
                            </table>
                        </div>
                    </div>
                </main>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    bookings: state.bookings,
});

export default connect(mapStateToProps, {
    getBookings,
})(BookingList);
