import React, { Component } from "react";
import { connect } from "react-redux";
import AOS from "aos";

// Styles
import "../../styles/components/blog/bloglist.scss";

// Assets

// Components
import Postcard from "./postcard.component";

// Actions
import { fetch } from 'redux-blogs';
import { setNumBlogPosts } from '../../actions/post.action';

class Bloglist extends Component {

    constructor(props) {
        super(props);

        this.showMore = this.showMore.bind(this);
    }

    componentDidMount() {
        AOS.init();
        this.props.fetch(this.props.numBlogPosts, 0, {status: 'Published'});
    }

    showMore() {
        this.props.setNumBlogPosts(this.props.numBlogPosts + 6)
        .then(data => {
            this.props.fetch(this.props.numBlogPosts, 0, {status: 'Published'});
        })
    }

    render() {
        let posts = "";

        if (
            this.props.posts &&
            this.props.posts.items &&
            this.props.posts.items.length > 0
        ) {
            posts = this.props.posts.items.map((item, index) => (
                <Postcard
                    item={item}
                    index={index}
                    image={item.header_image || '' }
                />
            ));
        } else {
            posts = (<h2>No posts ...</h2>);
        }

        return (
            <div className="container posts-list">
                <ul className="container list">
                    {posts}
                </ul>

                {
                    this.props.posts &&
                    this.props.posts.page_size < this.props.posts.total_items ? (
                    <div className="container see-more">
                        <div className="col-50"></div>
                        <div className="see-more col-50" onClick={this.showMore}>
                            <h5 data-aos="fade-right" data-aos-delay="300">See more</h5>
                            <p className="show-arrow">
                                <svg xmlns="http://www.w3.org/2000/svg" width="104.447" height="73.982" viewBox="0 0 104.447 73.982">
                                    <g id="Group_145" data-name="Group 145" transform="translate(-822 -1953.519)">
                                        <line id="Line_6" data-name="Line 6" x2="88.606" transform="translate(822 1990.346)" fill="none" stroke="#fff" stroke-width="20"/>
                                        <line id="Line_8" data-name="Line 8" x2="36.375" y2="37.308" transform="translate(882.625 1960.5)" fill="none" stroke="#fff" stroke-width="20"/>
                                        <line id="Line_9" data-name="Line 9" x2="36.375" y2="37.308" transform="translate(919.466 1983.966) rotate(90)" fill="none" stroke="#fff" stroke-width="20"/>
                                    </g>
                                </svg>
                            </p>
                        </div>
                    </div>
                    ) : ''
                }
                
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    posts: state.blogs,
    numBlogPosts: state.posts.numBlogPosts
});

export default connect(mapStateToProps, {
    fetch,
    setNumBlogPosts
})(Bloglist);
