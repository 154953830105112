import React, { Component } from "react";
import { connect } from "react-redux";
import AOS from "aos";
import history from '../../history';
import { DgPicture } from 'dg-utils';

// Styles
import "../../styles/components/home/moreworkredo.scss";

// Actions
import { visible } from "../../actions/visible.action";
import { setVideoModal } from '../../actions/modal.action';
import { getVideos } from "../../actions/video.action";

// Components
import ButtonAlt from '../button/buttonAlt.component';

// Assets
import { ourWorkVideos } from "../../assets/files/ourworkHome";
import background from '../../assets/Web Assets/backgrounds/sidedrawer_background.png';
import backgroundWebp from '../../assets/Web Assets/backgrounds/sidedrawer_background.webp';

class Morework extends Component {
    constructor(props) {
        super(props);

        this.state = {
            numVideos: 11,
            video: undefined
        };

        this.setVideo = this.setVideo.bind(this);
        this.navigateTo = this.navigateTo.bind(this);
    }

    componentDidMount() {
        AOS.init();

        this.props.getVideos(
            0, 
            1, 
            this.props.page || 'Home', 
            this.props.section ||`Weve made some great work`
        )
        .then(res => {
            this.setState({
                video: res.videos[0]
            })
        })
        .catch(res => {
            
        });
    }

    setVideo(id) {
        this.props.setVideoModal({
            showModal: true, 
            url: id
        });
    }

    navigateTo(uri) {
        window.scrollTo(0,0);
        history.push(uri);
    }

    render() {
        let videos = ourWorkVideos.slice(0, this.state.numVideos).map((item, index) => {
            return (
                <li 
                    key={index} 
                    onClick={() => this.setVideo(item.id)}
                    className="more-work-li"
                >
                    <DgPicture>
                        <img src={item.img} alt={item.alt} />
                    </DgPicture>
                </li>
            );
        });

        return (
            <div className="container morework">
                {   
                    this.state.video &&
                    this.state.video.files.length > 0 &&
                    this.state.video.files[0].url ? (
                        <video autoPlay playsInline muted loop id="back-video" style={{zIndex: this.props.isVisible ? "-1" : "-2", position: "fixed"}}>
                            <source
                                src={this.state.video.files[0].url}
                                type="video/mp4"
                            />
                            Sorry, your browser does not support video playback
                        </video>
                    ) : ''
                }

                <div className="title-container">
                    <h4 data-aos="fade-right">...and made some great work.</h4>
                </div>
                <div className="videos-container">
                    <ul data-aos="fade-down">
                        {videos}
                        <li 
                            key={this.state.numVideos + 1} 
                            className="more-work-li see-more-work"
                        >
                            
                            <div className="background-more-work-item">
                                <ButtonAlt 
                                    link="/our-work"
                                    text="See more work"
                                    aosEffect="fade-in"
                                    aosDelay="300"
                                />
                            </div>
                            <DgPicture>
                                <source srcSet={backgroundWebp} alt={`See more of our work`} />
                                <img src={background} alt={`See more of our work`} />
                            </DgPicture>
                        </li>
                    </ul>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    visibility: state.visible,
    videos: state.videos,
});

export default connect(mapStateToProps, { 
    visible,
    setVideoModal,
    getVideos
})(Morework);
