import React, { Component } from "react";
import AOS from "aos";
import { DgPicture } from 'dg-utils';

// Styles
import "../../styles/components/whoweare/studioGallery.scss";
import "../../styles/components/button/button.scss";

// Assets
import { studioImages } from "../../assets/files/studio";

// Components

export default class Studio extends Component {

    constructor(props) {
        super(props);

        this.getOffset = this.getOffset.bind(this);
    }

    componentDidMount() {
        AOS.init();
    }
    getRndInteger(min, max) {
        return Math.floor(Math.random() * (max - min)) + min;
    }

    getOffset(index) {
        let offset = '';
        let tmp = (index <= 5 ? index : index%5);

        switch (tmp) {
            case 1:
                offset = 'offset-top';
                break;
            case 2:
                offset = 'offset-bottom';
                break;
            case 3:
                offset = 'offset-right';
                break;
            case 4:
                offset = 'offset-left';
                break;
            case 5:
                offset = 'offset-left offset-bottom';
                break;
            default:
                break;
        }

        return offset;
    }

    render() {
        let items = [];
        let isMobile = window.screen.width < 1024;
        
        if(isMobile) {
            items = studioImages.slice(0,6).map((value, index) => (
                <div 
                    className={`studio-gallery-item ${this.getOffset(index)}`}
                >
                    <DgPicture>
                        <source srcSet={value.webp} className="studio-gallery-image" alt="studio life" />
                        <img src={value.img} className="studio-gallery-image" alt="studio life" />
                    </DgPicture>
                </div>
            ));
        } else {
            items = studioImages.map((value, index) => (
                <div 
                    className={`studio-gallery-item ${this.getOffset(index)}`}
                >
                    <DgPicture>
                    <source srcSet={value.webp} className="studio-gallery-image" alt="studio life" />
                        <img src={value.img} className="studio-gallery-image" alt="studio life" />
                    </DgPicture>
                </div>
            ));
        }

        return (
            <div className="studio-container">
                <div className="studio-title-container">
                    <div className="studio-title">
                        <h1 data-aos="fade-in">Studio Life </h1>
                    </div>
                </div>
                <div className="studio-gallery-container">
                    <div className="studio-gallery">
                        {items}
                    </div>
                    <div className="studio-gallery-footer">
                        <div className="studio-gallery-footer-item">
                            <div
                                className={`button-main`}
                            >
                                    <a 
                                        href="https://www.instagram.com/thereelistsfilm/"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    > 
                                        <svg 
                                            aria-hidden="true" 
                                            focusable="false" 
                                            data-prefix="fab" 
                                            data-icon="instagram" 
                                            className="svg-inline--fa fa-instagram fa-w-14" 
                                            role="img" 
                                            xmlns="http://www.w3.org/2000/svg" 
                                            viewBox="0 0 448 512"
                                        >
                                            <path 
                                                fill="currentColor" 
                                                className="primary-colour"
                                                d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"
                                            >
                                            </path>
                                        </svg>
                                        <span className="un">
                                            Follow our Instagram
                                        </span>
                                    </a>
                            </div>
                            
                        </div>
                        <div className="studio-gallery-footer-item">
                            <div
                                className={`button-main`}
                            >
                                    <a 
                                        href="https://www.facebook.com/thereelistsfilm"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    > 
                                        <svg 
                                            aria-hidden="true" 
                                            focusable="false" 
                                            data-prefix="fab" 
                                            data-icon="facebook-square" 
                                            class="svg-inline--fa fa-facebook-square fa-w-14" 
                                            role="img" 
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 448 512"
                                        >
                                            <path 
                                                fill="currentColor" 
                                                className="primary-colour"
                                                d="M400 32H48A48 48 0 0 0 0 80v352a48 48 0 0 0 48 48h137.25V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.27c-30.81 0-40.42 19.12-40.42 38.73V256h68.78l-11 71.69h-57.78V480H400a48 48 0 0 0 48-48V80a48 48 0 0 0-48-48z" 
                                            />
                                        </svg>
                                        <span className="un">
                                            Like our Facebook
                                        </span>
                                    </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
