import React, { Component } from "react";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import { Pagination } from "dg-pagination";

// Styles
import "../../../styles/components/admin/list.scss";
import "../../../styles/components/admin/admin.scss";

// Actions
import { getVideos } from "../../../actions/video.action";

// Components
import Sidebar from "../sidebar/sidebar.admin.component";

class VideoList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            page: 0,
            page_size: 20,
            total_pages: 0,
            total_items: 0,
        };

        this.paginate = this.paginate.bind(this);
    }

    componentDidMount() {
        this.paginate(this.state.page, this.state.page_size);
    }

    paginate(page, page_size) {
        this.props
            .getVideos(page, page_size)
            .then((res) => {
                this.setState({
                    page: res.page,
                    page_size: res.page_size,
                    total_pages: res.total_pages,
                    total_items: res.total_items,
                });
            })
            .catch((res) => {
                Swal.fire(`Error`, res.message, "error");
            });
    }

    render() {
        let rows = [];

        if (
            this.props.videos &&
            this.props.videos.videos &&
            this.props.videos.videos.length > 0
        ) {
            rows = this.props.videos.videos.map((value, index) => (
                <tr>
                    <td>{value.page}</td>
                    <td>{value.section}</td>
                    <td>
                        {value.files ? (
                            <Link
                                to={{pathname: `/admin/videos/create/${value._id}+${value.page}+${value.section}`}}
                                class="btn-primary"
                                
                            >
                                Change
                            </Link>
                        ) : (
                            ``
                        )}
                    </td>
                </tr>
            ));
        } else {
            rows = [
                <tr>
                    <td colSpan="5">No Videos available ...</td>
                </tr>,
            ];
        }

        return (
            <div className="admin-container">
                <Sidebar />
                <main>
                    <div className="List-Container">
                        <div className="list-navbar"></div>
                        <div className="list">
                            <table>
                                <thead>
                                    <tr>
                                        <th>Page</th>
                                        <th>Section</th>
                                        <th>Edit</th>
                                    </tr>
                                </thead>
                                <tbody>{rows}</tbody>
                            </table>
                            <Pagination
                                type="Standard"
                                paginate={this.paginate}
                                page_size={this.state.page_size}
                                page={this.state.page}
                                total_pages={this.state.total_pages}
                                total_items={this.state.total_items}
                            />
                        </div>
                    </div>
                </main>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    videos: state.videos,
});

export default connect(mapStateToProps, {
    getVideos,
})(VideoList);
