import React, { Component } from "react";
import "../../styles/components/button/button.scss";

// AOS
import AOS from "aos";
import { Link } from "react-router-dom";

export default class Button extends Component {
    constructor(props) {
        super(props);

        this.navigateTo = this.navigateTo.bind(this);
    }

    componentDidMount() {
        AOS.init();
    }

    navigateTo() {
        window.scrollTo(0,0);
    }

    render() {
        return (
            <div
                className={`button-main ${this.props.color}`}
                data-aos={this.props.aosEffect}
                data-aos-delay={this.props.aosDelay}
            >
                    <Link 
                        to={this.props.link}
                        onClick={() => this.navigateTo()}
                    > 
                        {this.props.img && (
                            <img src={this.props.img} alt="social" className="btn-img" />
                        )} 
                        <span className="un">
                            {this.props.text}
                        </span>
                    </Link>

            </div>
        );
    }
}
