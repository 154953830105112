import Tommy_Kevin from "../team/Tommy_Kevin.jpg";
import Sarah_Keogh from "../team/Sarah_Keogh.jpg";
import Riccardo_Minelli from "../team/Riccardo_Minelli.jpg";
import Filipe_Soldi from "../team/Filipe_Soldi.jpg";
import Martha_Lovett_Cullen from "../team/Martha_Lovett_Cullen.jpg";
import Annie_Walsh from "../team/Annie_Walsh.jpg";
import Arturo_Muniz from "../team/Arturo_Muniz.jpg";
import Luke_McGuinness from "../team/Luke_McGuinness.jpg";

export const team = [
  {
    name: "Tommy & Kevin",
    position: "Creative Directors",
    description: "Their collaboration began when Kevin helped Tommy find his cat at the age of five. Since then, they’ve continued to work together, founding The Reelists in 2015. As Creative Directors, they work closely with each and every client to develop unique video concepts. They oversee every stage of the production process, making sure that every video is one that they, and their clients, can be proud of. Just don’t bother them until they’ve had their morning, mid-morning, afternoon and late-afternoon coffees.",
    img: Tommy_Kevin,
  },
  {
    name: "Sarah Keogh",
    position: "Senior Producer",
    description: "Inspired by Lord of The Rings DVD extras as a teen, Sarah always knew she wanted to be someone that made movies happen. She turned to production when she produced a play about Harry Potter vs Superheroes as a teenager, and hasn’t looked back since. Sarah works her magic through multitasking and problem solving, looking after clients as she goes. She knows she’s done her job right when you can’t quite tell what she’s done but the production has gone flawlessly!",
    img: Sarah_Keogh,
  },
  {
    name: "Riccardo Minelli",
    position: "Director of Photography",
    description: "Once Upon A Time In… Ireland, Riccardo Minelli arrived, bringing with him his passion for collaboration, cinema and the Roma football team. Having grown up in darkrooms helping his father develop photos in Italy, Riccardo has always embraced the creativity that comes with the camera. An advocate for teamwork, he is always ready to work efficiently and effectively with directors, clients and crew, passing the ball when needed and scoring goals the whole way through.",
    img: Riccardo_Minelli,
  },
  {
    name: "Filipe Soldi",
    position: "Post-Production Manager",
    description: "Hailing from São Paulo in Brazil, Filipe brings his energy, ingenuity and creativity to every project. Growing up, Filipe was full of big ideas - now he watches them come to life through his videos. A natural perfectionist, Filipe lives by the motto that everything was made to solve a problem, and coming up with new ways to meet those challenges is all part of the fun. He also loves his dogs, football, drumming, cycling, and getting to see the effects that projects can have on other people's lives.",
    img: Filipe_Soldi,
  },
  {
    name: "Martha Lovett Cullen",
    position: "Junior Producer",
    description: "From call sheets to schedules to shoot briefs; Martha’s got it covered. An on-set enthusiast, she can be found with her trusty clipboard as a producer, AD, PA or just a spare pair of hands. Otherwise you’ll find her staying inspired with her weekly cinema pilgrimage or listening to the Off Menu podcast. Martha loves to watch the pieces of a project come together in the form of crossed off lists and ticked boxes, all in scrupulously neat handwriting.",
    img: Martha_Lovett_Cullen,
  },
  {
    name: "Annie Walsh",
    position: "Editor",
    description: "Swapping out her piano keys for a computer keyboard, Annie still plays both with ease. Having left Windows Movie Maker behind with her younger self, she now pushes her knowledge and skill set with every edit, taking on each challenge with determination. Until it gets to version 14… Then it calls for another coffee to help her across the finish line. Annie knows what it takes to tell a story while doing justice to the footage, the vision and those behind it.",
    img: Annie_Walsh,
  },
  {
    name: "Arturo Muñiz",
    position: "Editor",
    description: "Arturo was drawn to editing after finishing the series ‘Avatar: The Last Airbender’. His desire to explore stories by making montages and creating narratives simply made him more fond of the process, discovering his talent for it along the way. Having travelled from central Mexico in 2020, Arturo’s flair for introducing fresh new perspectives gives a unique direction to every project. A lover of all things musical, Arturo also lets rhythm and feeling influence his timelines, allowing dulcet tones to wash over him as he visualises stories taking shape.",
    img: Arturo_Muniz,
  },
  {
    name: "Luke McGuinness",
    position: "Assistant Editor",
    description: "A man of many talents, Luke’s assistant editor hat is only one of a vast collection. Starting out in the indie-rock music scene, Luke eventually found himself exploring film when he realised it offered more room for creativity and storytelling. Coaching kids football and surfing are just some of his many other passions, that is when he’s not busy auditioning for X-Factor (really!). Luke has filled every role you can imagine on set, so rest assured you’re in good hands when you see his bright blue moped speeding towards you.",
    img: Luke_McGuinness,
  }
];
