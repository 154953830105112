import React, { Component } from "react";
import { CMSSection } from "dg-cms";
import { DgPicture } from "dg-utils";

// Components
import ServiceTemplate from "./services.template";
import Showreelredo from "../home/showreelredo.component";
import LogoBlock from "./logo.block";

// Assets
import HeaderImage from "../../assets/service_pages/Full Service Production/full-service-production-hero.png";
import Image from "../../assets/service_pages/Full Service Production/details-1.png";
import FooterImage from "../../assets/service_pages/Full Service Production/contact-1.png";
import HeaderImageMobile from "../../assets/service_pages/Full Service Production/Mobile/full-service-production-hero.png";
import ImageMobile from "../../assets/service_pages/Full Service Production/Mobile/details-1.png";
import FooterImageMobile from "../../assets/service_pages/Full Service Production/Mobile/contact-1.png";
import client_logos from "../../assets/service_pages/Logos for Animation and Drone Page.png";

export default class FullServiceProduction extends Component {
    render() {
        const headerImages = (cmsElmt) => (
            <DgPicture>
                <source
                    srcSet={HeaderImageMobile}
                    media="(max-width: 1024px)"
                />
                <img
                    src={HeaderImage}
                    alt={cmsElmt(
                        "Header Image Alt",
                        "text",
                        "Videography Header"
                    )}
                />
            </DgPicture>
        );

        const footerImages = (cmsElmt) => (
            <DgPicture>
                <source
                    srcSet={FooterImageMobile}
                    media="(max-width: 1024px)"
                />
                <img
                    src={FooterImage}
                    alt={cmsElmt(
                        "Header Image Alt",
                        "text",
                        "Videography Header"
                    )}
                />
            </DgPicture>
        );

        return (
            <CMSSection name="Videography Page">
                {({ cmsElmt }) => (
                    <ServiceTemplate
                        title={"Full Service Production"}
                        page={"Full Service Production"}
                        headerImages={headerImages(cmsElmt)}
                        footerImages={footerImages(cmsElmt)}
                        section={"Header"}
                        body={
                            <React.Fragment>
                                <CMSSection name="Videography Services">
                                    {({ cmsElmt }) => (
                                        <Showreelredo
                                            left={
                                                <div
                                                    className="showreel-title"
                                                    data-aos="fade-up"
                                                >
                                                    <h3>
                                                        We produce.
                                                        <br />
                                                        We shoot.
                                                        <br />
                                                        We Edit.
                                                        <br />
                                                        We Do
                                                        <br />
                                                        Everything!
                                                    </h3>
                                                </div>
                                            }
                                            right={
                                                <div className="text-video">
                                                    <div className="showreel-para-left">
                                                        <h7>
                                                            {cmsElmt(
                                                                "Full Service Paragraph 1",
                                                                "text",
                                                                "Full-service video production means that we are the only call you will ever need to make when creating a corporate video for your company. Regardless of how big or small the job, The Reelists is your one stop shop for making it all happen. We cater to all types and scales of production, offering everything from creative consultancy and concept development, through to producing, shooting and post-production."
                                                            )}
                                                        </h7>
                                                        <h7>
                                                            {cmsElmt(
                                                                "Full Service Paragraph 2",
                                                                "text",
                                                                "Our team of award-winning creatives include videographers, creative directors, scriptwriters, voice-over artists, cinematographers, and editors. Our team use all the latest in video and editing equipment to make sure that you are provided with a clean-cut, professional video that represents your brand to the fullest."
                                                            )}
                                                        </h7>
                                                    </div>
                                                    <div className="showreel-vid">
                                                        {/*
                                                        <iframe
                                                            title="The Reelists Property Showreel"
                                                            src="https://player.vimeo.com/video/414007459?"
                                                            width="640"
                                                            height="360"
                                                            frameborder="0"
                                                            allow="autoplay; fullscreen; picture-in-picture"
                                                            allowfullscreen
                                                        />*/}
                                                        <DgPicture>
                                                            <source
                                                                srcSet={
                                                                    ImageMobile
                                                                }
                                                                media="(max-width: 1024px)"
                                                            />
                                                            <img
                                                                src={Image}
                                                                alt={cmsElmt(
                                                                    "Image Alt Text",
                                                                    "text",
                                                                    "Videography"
                                                                )}
                                                            />
                                                        </DgPicture>
                                                    </div>
                                                </div>
                                            }
                                        />
                                    )}
                                </CMSSection>
                                <LogoBlock
                                    logo={client_logos}
                                    logoAlt={`Property client logos`}
                                />{" "}
                            </React.Fragment>
                        }
                    />
                )}
            </CMSSection>
        );
    }
}
