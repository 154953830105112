import React, { Component } from "react";

//Styles
import '../../styles/components/services/logo.scss';

// Components

// Assets

export default class LogoBlock extends Component {

    render() {
        return (
            <div className="logo-block-container">
                <div className={`logo-block ${this.props.colour ? this.props.colour : ''}`}>
                    {
                        this.props.logo ? 
                        (
                            <img className="logo-block-img" src={this.props.logo} alt={this.props.logoAlt || ''} />
                        ) : ''
                    }
                </div>
            </div>
        );
    }
}
