import React, { Component } from "react";
import { connect } from "react-redux";
import Swal from "sweetalert2";

// Styles
import "../../../styles/components/admin/admin.scss";

// Actions
import { getBookingById } from "../../../actions/booking.action";

// Components
import Sidebar from "../sidebar/sidebar.admin.component";
import { BookingForm } from "./booking.form";

class ViewBooking extends Component {
    constructor(props) {
        super(props);

        this.state = {
            booking: {},
        };
    }

    componentDidMount() {
        let urlParams = window.location.pathname;
        let params = urlParams.split("/");

        if (params.length >= 4) {
            this.props
                .getBookingById(params[3])
                .then((res) => this.setState({ booking: res }))
                .catch((res) => {
                    Swal.fire("Error", res.message, "error");
                });
        }
    }

    render() {
        return (
            <div className="admin-container">
                <Sidebar />
                    {this.state.booking.booking &&
                <main>
                    <BookingForm
                        onSubmit={() => {}}
                        disableAll={true}
                        initialValues={this.state.booking.booking}
                        enableReinitialize={true}
                    />
                </main>}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    bookings: state.bookings,
});

export default connect(mapStateToProps, {
    getBookingById,
})(ViewBooking);
