import React from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { dgInput, dgUpload } from "dg-forms";
import "../../../styles/components/admin/form.scss";

let Video_Form = (props) => {
    const {
        handleSubmit,
        handleSelectedFile,
        submitting,
        onSubmit,
        disableAll,
    } = props;

    return (
        <div className="admin-form-container">
            <form onSubmit={handleSubmit(onSubmit)}>
                <Field
                    type="hidden"
                    name="page"
                    component={dgInput}
                    disabled={true}
                    display={false}
                />
                <Field
                    type="hidden"
                    name="id"
                    component={dgInput}
                    disabled={true}
                />
                <Field
                    type="hidden"
                    name="section"
                    component={dgInput}
                    disabled={true}
                />
                <Field
                    containerClass="field-group"
                    multiple={false}
                    label={`Video Upload for ${props.page} -> ${props.section}`}
                    labelClass="required"
                    name="files"
                    onChange={handleSelectedFile}
                    accept={"video/*"}
                    component={dgUpload}
                    disabled={disableAll}
                />
                <button
                    type="submit"
                    className="btn-primary"
                    disabled={submitting || disableAll}
                >
                    Save
                </button>
            </form>
        </div>
    );
};

Video_Form = reduxForm({
    form: "Video_Form",
})(Video_Form);

Video_Form = connect((state) => {}, {})(Video_Form);

export const VideoForm = Video_Form;
