import React, { Component } from "react";
import { Link } from "react-router-dom";
import AOS from "aos";
import { DgHelmet } from "dg-utils";

// Assets

// Style
import "../../styles/components/ourservices/ourservices.scss";

// Components
import Header from "../header/header.component";
import Footervideo from "../footervideo/footervideo.component";
import Services from "./services.component";
import TrackVisibility from "react-on-screen";
import VimeoModal from "../modals/vimeoModal/vimeoModal";
import { CMSSection } from "dg-cms";

import seojson from "../../assets/files/seojson.js";

export default class Ourservices extends Component {
    constructor(props) {
        super(props);

        this.navigateTo = this.navigateTo.bind(this);
    }

    componentDidMount() {
        AOS.init();
    }

    navigateTo() {
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <CMSSection name="Our Services">
                {({ cmsElmt }) => (
                    <div className="ourservices">
                        <DgHelmet seojson={seojson}></DgHelmet>
                        <TrackVisibility
                            partialVisibility
                            style={{ width: "100%" }}
                        >
                            {({ isVisible }) => (
                                <Header
                                    isVisible={isVisible}
                                    title="Our services"
                                />
                            )}
                        </TrackVisibility>
                        <CMSSection name="Our Services Block">
                            {({ cmsElmt }) => (
                                <Services
                                    title={cmsElmt(
                                        "title",
                                        "text",
                                        "Our Services"
                                    )}
                                    content={
                                        <div className="service-para">
                                            <h5 data-aos="fade-right">
                                                {cmsElmt(
                                                    "body",
                                                    "text",
                                                    "We Do Everything. It’s a motto we take seriously. We handle every step of the video production process, from concept development to editing and beyond. If you can’t find a service here that you need, just ask us; we’ll sort you out."
                                                )}
                                            </h5>
                                        </div>
                                    }
                                />
                            )}
                        </CMSSection>
                        <div className="container services-list">
                            <Link
                                to="/drone"
                                className="drone"
                                onClick={() => this.navigateTo()}
                            >
                                <h3 data-aos="fade-up" data-aos-offset="0">
                                    Drone Videography
                                </h3>
                            </Link>
                            <Link
                                to="/animation"
                                className="animation"
                                onClick={() => this.navigateTo()}
                            >
                                <h3 data-aos="fade-up" data-aos-offset="0">
                                    Animation
                                </h3>
                            </Link>
                            <Link
                                to="/production-services"
                                className="services-for-agencies"
                                onClick={() => this.navigateTo()}
                            >
                                <h3 data-aos="fade-up" data-aos-offset="0">
                                    Services for Agencies
                                </h3>
                            </Link>
                            <Link
                                to="/publiccomms"
                                className="services-for-charities"
                                onClick={() => this.navigateTo()}
                            >
                                <h3 data-aos="fade-up" data-aos-offset="0">
                                    Public Communications Services
                                </h3>
                            </Link>
                            <Link
                                to="/property-videos"
                                className="property"
                                onClick={() => this.navigateTo()}
                            >
                                <h3 data-aos="fade-up" data-aos-offset="0">
                                    Services for Property Sector
                                </h3>
                                    </Link>
                                        {/*
                            <Link
                                to="/full-service-production"
                                className="full-service"
                                onClick={() => this.navigateTo()}
                            >
                                <h3 data-aos="fade-up" data-aos-offset="0">
                                    Full Service Production
                                </h3>
                            </Link>
                            <Link
                                to="/videography"
                                className="videography"
                                onClick={() => this.navigateTo()}
                            >
                                <h3 data-aos="fade-up" data-aos-offset="0">
                                    Videography
                                </h3>
                            </Link>
                            */}
                        </div>
                        <TrackVisibility
                            partialVisibility
                            style={{ width: "100%" }}
                        >
                            {({ isVisible }) => (
                                <Footervideo isVisible={isVisible} />
                            )}
                        </TrackVisibility>

                        {/* Modals */}
                        <VimeoModal />
                    </div>
                )}
            </CMSSection>
        );
    }
}
