import React, { Component } from "react";
import { connect } from "react-redux";
import "./testimonials.scss";
import { Swipeable } from "react-swipeable";

import { testimonials } from "../../assets/files/testimonials";

class TestimonialFull extends Component {
    constructor(props) {
        super(props);

        this.state = {
            currentIndex: 0,
            timing: 6000,
            isAutoScrollOn: false
        };

        this.setIndex = this.setIndex.bind(this);
        if(this.state.isAutoScrollOn) {
            this.interval = setInterval(
                () => this.setIndex(this.state.currentIndex + 1),
                this.state.timing
            );
        }
        
    }

    setIndex(index) {
        if (index < 0) {
            index = testimonials.length - 1;
        }

        if (index >= testimonials.length) {
            index = 0;
        }

        this.setState({ currentIndex: index });

        // Reset autocycle to prevent jumpyness.
        if(this.state.isAutoScrollOn) {
            clearInterval(this.interval);
            this.interval = setInterval(
                () => this.setIndex(this.state.currentIndex + 1),
                this.state.timing
            );
        }

        // If Animation Library is passed in, reset animation.
        if (this.props.AOS) {
            this.props.AOS.refresh();
        }
    }

    render() {
        let indictors = testimonials.map((testimonial, index) => (
            <div
                key={`indicator_no_${index}`}
                type="button"
                className={
                    this.state.currentIndex === index
                        ? "testimonial-indicator active"
                        : "testimonial-indicator"
                }
                onClick={() => this.setIndex(index)}
            ></div>
        ));

        const swipeConfig = {
            onSwipedLeft: () => this.setIndex(this.state.currentIndex + 1),
            onSwipedRight: () => this.setIndex(this.state.currentIndex - 1),
            preventDefaultTouchmoveEvent: true,
            trackMouse: true,
        };

        return (
            <Swipeable {...swipeConfig} className="testimonialFull">
                <div className="left">
                    <button
                        type="button"
                        className=""
                        onClick={() => this.setIndex(this.state.currentIndex - 1)}
                    >
                        <svg 
                            aria-hidden="true" 
                            focusable="false" 
                            data-prefix="fas" 
                            data-icon="angle-left" 
                            class="svg-inline--fa fa-angle-left fa-w-8" 
                            role="img" 
                            xmlns="http://www.w3.org/2000/svg" 
                            viewBox="0 0 256 512"
                        >
                            <path 
                                fill="currentColor" 
                                d="M31.7 239l136-136c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9L127.9 256l96.4 96.4c9.4 9.4 9.4 24.6 0 33.9L201.7 409c-9.4 9.4-24.6 9.4-33.9 0l-136-136c-9.5-9.4-9.5-24.6-.1-34z" 
                            />
                        </svg>
                    </button>
                </div>
                <div className="content">
                    <div
                        key={`testimonial_full_${this.state.currentIndex}`}
                        data-aos="fade-in"
                    >
                        {testimonials[this.state.currentIndex].comments ? (
                            <h6 className="spacing-text">
                                {
                                    testimonials[this.state.currentIndex]
                                        .comments
                                }
                            </h6>
                        ) : (
                            ""
                        )}
                        <h6>
                            -{testimonials[this.state.currentIndex].name}
                            <br />
                            {testimonials[this.state.currentIndex].company}
                        </h6>
                    </div>
                    <div className="indicator-container">
                        {indictors}
                    </div>
                </div>
                <div className="right">
                    <button
                        type="button"
                        className=""
                        onClick={() => this.setIndex(this.state.currentIndex + 1)}
                    >
                        <svg 
                            aria-hidden="true" 
                            focusable="false" 
                            data-prefix="fas" 
                            data-icon="angle-right" 
                            class="svg-inline--fa fa-angle-right fa-w-8" 
                            role="img" 
                            xmlns="http://www.w3.org/2000/svg" 
                            viewBox="0 0 256 512"
                        >
                            <path 
                                fill="currentColor" 
                                d="M224.3 273l-136 136c-9.4 9.4-24.6 9.4-33.9 0l-22.6-22.6c-9.4-9.4-9.4-24.6 0-33.9l96.4-96.4-96.4-96.4c-9.4-9.4-9.4-24.6 0-33.9L54.3 103c9.4-9.4 24.6-9.4 33.9 0l136 136c9.5 9.4 9.5 24.6.1 34z"
                            />
                        </svg>
                    </button>
                </div>
            </Swipeable>
        );
    }
}

export default connect(null, {})(TestimonialFull);
