import React, { Component } from "react";
import AOS from "aos";

// Styles
import "../../styles/components/home/testimonialsredoV2.scss";
import "../../../node_modules/aos/dist/aos.css";

// Assets
import logos from "../../assets/Web Assets/backgrounds/Homepage_Client_Logos.png";
import clutch from "../../assets/clutch/clutch.png";

// Components
import TestimonialFull from "../testimonials/testimonial_full.component";

export default class Testimonialsredo extends Component {
    componentDidMount() {
        AOS.init();
    }
    render() {
        return (
            <div className="container testimonials-updated">
                <div className="testimonials-header">
                    <h4 data-aos="fade-in">
                        We've worked with some great people...
                    </h4>
                </div>
                <div className="testimonials-body">
                    <div className="testimonials">
                        <TestimonialFull />
                    </div>
                    <div className="clutch">
                        <div class="clutch-widget">
                            <a
                                href="https://clutch.co/profile/reelists?utm_source=widget&utm_medium=widget_2&utm_campaign=widget&utm_content=logo"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <img
                                    src={clutch}
                                    alt="The Reelists Clutch Rating"
                                />
                            </a>
                        </div>
                    </div>
                </div>
                <div className="testimonials-footer">
                    <div className="testimonials-img">
                        <img src={logos} alt="The Reelists Client Logos" />
                    </div>
                </div>
            </div>
        );
    }
}
