import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { dgInput, required, email, phoneNumber } from 'dg-forms';
import '../../../styles/components/admin/form.scss';

let Booking_Form = (props) => {
  const { 
      handleSubmit, 
      submitting, 
      onSubmit,  
      disableAll
    } = props; 

  return (
    <div className="admin-form-container">
      <form onSubmit={handleSubmit(onSubmit)}>
        <h3>Booking details</h3>
        <Field
            name="your_details.name"
            type="text"
            component={dgInput}
            label="Name"
            labelClass="required"
            validate={[required]}
            disabled={disableAll}
        />
        <Field
            name="your_details.subject"
            type="text"
            component={dgInput}
            label="Subject"
            labelClass="required"
            validate={[required]}
            disabled={disableAll}
        />
        <Field
            name="your_details.number"
            type="text"
            component={dgInput}
            containerClass="field-group"
            inputClass=""
            label="Number"
            labelClass="required"
            validate={[required, phoneNumber]}
            disabled={disableAll}
        />
        <Field
            name="your_details.email"
            type="text"
            component={dgInput}
            containerClass="field-group"
            inputClass=""
            label="Email"
            labelClass="required"
            validate={[required, email]}
            disabled={disableAll}
        />
        <Field
            name="your_details.date"
            type="text"
            component={dgInput}
            containerClass="field-group"
            inputClass=""
            label="Date"
            labelClass="required"
            validate={[required]}
            disabled={disableAll}
        />
        <Field
            name="your_details.time"
            type="text"
            component={dgInput}
            containerClass="field-group"
            inputClass=""
            label="Time"
            labelClass="required"
            validate={[required]}
            disabled={disableAll}
        />

        {
          !disableAll ? (
            <button 
              type="submit"
              className="btn-main"
              disabled={submitting}
            >
              Save
            </button>
          ) : ''
        }
      </form>
    </div>
  );
};

Booking_Form = reduxForm({
  form: 'Booking_Form'
})(Booking_Form);

Booking_Form = connect(null,{ })(Booking_Form);

export const BookingForm = Booking_Form;
