import React, { Component } from "react";
import history from "../../../history";
import { connect } from "react-redux";
import { EditCMS } from "dg-cms";

// Styles
import "../../../styles/components/admin/list.scss";
import "../../../styles/components/admin/admin.scss";

// Components
import Sidebar from "../sidebar/sidebar.admin.component";

class List extends Component {
    render() {
        return (
            <div className="container">
                <Sidebar />
                <EditCMS
                    onEdit={(item) => {
                        history.push(`/admin/cms/${item._id}`);
                    }}
                    location={this.props.location}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(List);
