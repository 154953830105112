import React, { Component } from "react";
import AOS from "aos";

// Styles
import "../../styles/components/ourservices/services.scss";

// Assets
import { getServices } from '../../assets/files/services';

export default class Services extends Component {
    componentDidMount() {
        AOS.init();
    }
    render() {
        let renderServices = getServices('black', '').map((service, index) => (
            <div 
                key={`service_page_service_${index}`}
                className={`img-element`} 
            >
                {service.icon}
                <p className="white">{service.name}</p>
            </div>
        ));

        return (
            <div className="container our-services">
                <div className="col-55">
                    <h2 data-aos="fade-in">{this.props.title ? this.props.title : ''}</h2>
                    {this.props.content ? this.props.content : ''}
                </div>
                <div className="col-45">
                    <div className="img-container" data-aos="fade-right">
                        {renderServices}
                    </div>
                </div>
            </div>
        );
    }
}
