import React, { Component } from "react";
import TrackVisibility from "react-on-screen";

// Components
import Header from "../header/header.component.js";
import Testimonialsredo from "../home/testimonialsredo.component.js";
import Morework from "../home/morework.component.js";
import Footervideo from "../footervideo/footervideo.component.js";
import Team from "../home/team.component.js";
import Showreelredo from "../home/showreelredo.component.js";
import Services from "../home/services.component";
import VimeoModal from "../modals/vimeoModal/vimeoModal";
import { DgHelmet } from "dg-utils";

import seojson from "../../assets/files/seojson.js";

export default class Videosforyourbusiness extends Component {
    componentDidMount() {
        let queryParams = new URLSearchParams(this.props.location.search);
        console.log(queryParams);
    }

    render() {
        return (
            <div className="home">
                <DgHelmet seojson={seojson}></DgHelmet>
                <TrackVisibility partialVisibility style={{ width: "100%" }}>
                    {({ isVisible }) => (
                        <Header
                            isVisible={isVisible}
                            title="Your Business Deserves Beautiful Video"
                        />
                    )}
                </TrackVisibility>
                <Showreelredo
                    left={
                        <div className="showreel-title">
                            <h2 data-aos="fade-up">
                                All kinds of videography -
                            </h2>
                            <h2 data-aos="fade-up">delivered</h2>
                            <h2 data-aos="fade-up">on Time,</h2>
                            <h2 data-aos="fade-up">on Budget,</h2>
                            <h2 data-aos="fade-up">and above expectations</h2>
                        </div>
                    }
                />
                <Testimonialsredo />
                <TrackVisibility partialVisibility style={{ width: "100%" }}>
                    {({ isVisible }) => <Morework isVisible={isVisible} />}
                </TrackVisibility>
                <Services
                    title={`So, what do we do?`}
                    content={
                        <div className="services-text-container">
                            <div className="service-para" data-aos="fade-right">
                                <p>
                                    Video production can be a daunting prospect.
                                    That’s why our motto is We Do Everything: we
                                    want to make it as easy as possible for you
                                    by handling every step of the process from
                                    start to finish.
                                </p>
                            </div>
                            <div className="service-para" data-aos="fade-right">
                                <h5 className="service-title">
                                    We produce.&nbsp;
                                </h5>
                                <p>
                                    We discuss your brand with you and your
                                    goals for your video production. What is the
                                    story that you want to tell and who is the
                                    audience that you’re aiming to reach? Every
                                    video we make is completely unique and
                                    focused on your message.
                                </p>
                            </div>
                            <div className="service-para" data-aos="fade-right">
                                <h5 className="service-title">
                                    We shoot.&nbsp;
                                </h5>
                                <p>
                                    This is the fun part! Whether it’s one of
                                    our highly talented videographers, or a crew
                                    of 20, you’ll be working with a team of
                                    Reelists dedicated to telling your story in
                                    the most interesting way possible. We use
                                    only the best equipment, and are constantly
                                    investing in new technology.
                                </p>
                            </div>
                            <div className="service-para" data-aos="fade-right">
                                <h5 className="service-title">
                                    We edit.&nbsp;
                                </h5>
                                <p>
                                    Next our team of talented editors get to
                                    work, cutting the video together, adding
                                    animation, 3D graphics and cinematic music
                                    to really grab people’s attention. Our
                                    priority during the edit is creating a video
                                    that lives up to your expectations. We leave
                                    plenty of time for your feedback, so you can
                                    be sure the finished video is one you’re
                                    truly happy with.
                                </p>
                            </div>
                            <div className="service-para" data-aos="fade-right">
                                <h5 className="service-title">
                                    And we deliver.&nbsp;
                                </h5>
                                <p className="motto">
                                    On time. On budget. And above expectations.
                                </p>
                            </div>
                        </div>
                    }
                />
                <Team />
                <TrackVisibility partialVisibility style={{ width: "100%" }}>
                    {({ isVisible }) => <Footervideo isVisible={isVisible} />}
                </TrackVisibility>

                {/* Modals */}
                <VimeoModal />
            </div>
        );
    }
}
