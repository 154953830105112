import React, { Component } from "react";
import { connect } from "react-redux";

// Actions
import { visible } from "../../actions/visible.action";
import { setVideoModal } from '../../actions/modal.action';

// Styles
import "../../styles/components/ourwork/ourwork.scss";

// Assets
import background from '../../assets/Web Assets/backgrounds/diagonals.svg';

// Components
import ButtonAlt from '../button/buttonAlt.component';

class VideoStack extends Component {
    constructor(props) {
        super(props);

        this.state = {
            videoId: null,
        };

        this.setVideo = this.setVideo.bind(this);
    }

    setVideo(id) {
        // this.setState({ videoId: id });
        // this.props.visible();

        this.props.setVideoModal({
            showModal: true, 
            url: id
        });
    }

    render() {
        let videos = [];

        if(
            this.props.videos &&
            this.props.videos.length > 0
        ) {
            videos = this.props.videos.map((item, index) => {
                return (
                    <li key={index} onClick={() => this.setVideo(item.id)}>
                        <img src={item.img} alt={`The Reelists`} />
                    </li>
                );
            });
        }
        
        return (
            <div className="ourwork">
                <div className="vids-container">
                    <ul>
                        {videos}
                        {
                            this.props.showSeeMore ? (
                                <li 
                                    key={this.props.videos.length + 1} 
                                    className="more-work-li see-more-work"
                                >
                                    <div className="background-more-work-item">
                                        <ButtonAlt 
                                            link="/our-work"
                                            text="See more work"
                                            aosEffect="fade-in"
                                            aosDelay="300"
                                        />
                                    </div>
                                    <img src={background} alt={`See more  of our work`} />
                                </li>
                            ) : ''
                        }
                    </ul>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    visibility: state.visible,
});

export default connect(mapStateToProps, { 
    visible,
    setVideoModal 
})(VideoStack);
