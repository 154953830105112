import React, { Component } from "react";
import { connect } from "react-redux";
import AOS from "aos";

// Actions
import { getVideos } from "../../actions/video.action";

// Styles
import "../../styles/components/footervideo/footervideoredo.scss";

// Assets
import instagram from "../../assets/Web Assets/social/instagram-1.svg";
import facebook from "../../assets/Web Assets/social/facebook-1.svg";
import vimeo from "../../assets/Web Assets/social/vimeo-1.svg";
import linkedin from "../../assets/Web Assets/social/linkedin-1.svg";
import twitter from "../../assets/Web Assets/social/twitter-1.svg";

// Components
import Button from "../../components/button/button.component";

class Footervideo extends Component {
    constructor(props) {
        super(props);

        this.state = {
            video: undefined,
        };
    }

    componentDidMount() {
        AOS.init();

        this.props
            .getVideos(
                0,
                1,
                this.props.page || "Home",
                this.props.section || "Footer"
            )
            .then((res) => {
                this.setState({
                    video: res.videos[0],
                });
            })
            .catch((res) => {});
    }

    render() {
        return (
            <div className="container footervideo">
                {this.props.images ? (
                    <div className="footer-image">{this.props.images}</div>
                ) : this.state.video &&
                  this.state.video.files.length > 0 &&
                  this.state.video.files[0].url ? (
                    <video
                        autoPlay
                        playsInline
                        muted
                        loop
                        id="footer-video"
                        style={{
                            zIndex: this.props.isVisible ? "-1" : "-2",
                            position: "fixed",
                        }}
                    >
                        <source
                            src={this.state.video.files[0].url}
                            type="video/mp4"
                        />
                        Sorry, your browser does not support video playback
                    </video>
                ) : (
                    ""
                )}
                <div className="col-30">
                    <h2 data-aos="fade-right">
                        Let's get
                        <br /> started!
                    </h2>
                </div>

                <div className="col-70">
                    <div className="btn-container">
                        <div className="btn-wrapper">
                            <Button
                                color={``}
                                aosEffect="fade-right"
                                aosDelay="300"
                                link={`/contact-us`}
                                text={`Book a Free Consultation`}
                            />
                        </div>
                    </div>
                    <div className="socials">
                        <a
                            href="https://instagram.com/thereelistsfilm"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <img
                                src={instagram}
                                alt="instagram"
                                data-aos="fade-in"
                            />
                        </a>
                        <a
                            href="https://www.facebook.com/thereelistsfilm"
                            i
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <img
                                src={facebook}
                                alt="facebook"
                                data-aos="fade-in"
                            />
                        </a>
                        <a
                            href="https://vimeo.com/thereelistsfilm"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <img src={vimeo} alt="vimeo" data-aos="fade-in" />
                        </a>
                        <a
                            href="https://ie.linkedin.com/company/thereelists"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <img
                                src={linkedin}
                                alt="linkedin"
                                data-aos="fade-in"
                            />
                        </a>
                        <a
                            href="https://twitter.com/TheReelistsFilm"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <img
                                src={twitter}
                                alt="twitter"
                                data-aos="fade-in"
                            />
                        </a>
                    </div>
                    <a
                        className="footer-contact"
                        href="mailto:info@thereelists.com"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <h4
                            data-aos="fade-right"
                            data-aos-anchor-placement="top-left"
                        >
                            info@thereelists.com
                        </h4>
                    </a>
                    {/*
                    <a className="footer-contact" href="tel:012444185">
                        <h4
                            data-aos="fade-right"
                            data-aos-anchor-placement="top-left"
                        >
                            01-2444185
                        </h4>
                    </a>
                    <div className="footer-contact">
                        <h4
                            data-aos="fade-right"
                            data-aos-anchor-placement="top-left"
                        >
                            01-2444185
                        </h4>
                    </div>
                    <h4
                        className="footer-contact"
                        data-aos="fade-right"
                        data-aos-anchor-placement="top-left"
                    >
                        9 Baggot Street Upper, Dublin 4
                    </h4>
                    */}
                    <div
                        className="digi footer-contact"
                        data-aos="fade-right"
                        data-aos-anchor-placement="top-left"
                    >
                        <h7>
                            Website designed and built by&nbsp;
                            <a href="https://digigrow.ie" className="underline">
                                Digigrow
                            </a>
                        </h7>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    videos: state.videos,
});

export default connect(mapStateToProps, {
    getVideos,
})(Footervideo);
