import React, { Component } from "react";
import { connect } from "react-redux";
import Aos from "aos";
import { CMSSection } from "dg-cms";
// Styles
import "../../styles/components/home/showreelredo.scss";
import "../../../node_modules/aos/dist/aos.css";

// Components
import { setVideoModal } from "../../actions/modal.action";

class Showreelredo extends Component {
    componentDidMount() {
        Aos.init();
    }

    render() {
        return (
            <CMSSection name="Show Reel">
                {({ cmsElmt }) => (
                    <div className="container showreel-container">
                        <div className="col-50">
                            {this.props.left || (
                                <div
                                    className="showreel-title"
                                    data-aos="fade-up"
                                >
                                    <h1>Your</h1>
                                    <h1>business</h1>
                                    <h1>deserves</h1>
                                    <h1>beautiful</h1>
                                    <h1>video.</h1>
                                </div>
                            )}
                        </div>
                        <div className="col-50">
                            {this.props.right || (
                                <div className="text-video">
                                    <div className="showreel-para">
                                        <h7>
                                            {cmsElmt(
                                                "Show Reel Intro",
                                                "text",
                                                "Our video production company is all about you. Telling your stories. Engaging Your audience. And most of all, reaching your business goals. So we’re not just one of the most respected video production companies in Ireland, we’re your creative partner. From explainer videos to corporate videos, from defining your message and developing great ideas to ensuring your video is reaching the right audience, we’re with you every step of the way. So you can be guaranteed your videos will not only look great, but achieve great results too."
                                            )}
                                        </h7>
                                    </div>
                                    <div
                                        className="showreel-vid"
                                        onClick={() =>
                                            this.props.setVideoModal({
                                                showModal: true,
                                                url: "307170578",
                                            })
                                        }
                                    >
                                        <iframe
                                            title="The Reelists Showreel"
                                            src="https://player.vimeo.com/video/307170578?"
                                            width="640"
                                            height="360"
                                            frameborder="0"
                                            allow="autoplay; fullscreen; picture-in-picture"
                                            allowfullscreen
                                        />
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                )}
            </CMSSection>
        );
    }
}

export default connect(null, {
    setVideoModal,
})(Showreelredo);
