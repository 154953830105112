import {
    UPLOAD_VIDEO,
    GET_VIDEOS,
    SET_IS_LOADING_VIDEO
} from "../actions/types";

const initialState = {
    isLoading: false,
    videos: [],
    currentVideo: {},
    message: "",
    ok: true,
    errors: []
};

export default function (state = initialState, action) {
    switch (action.type) {
        case SET_IS_LOADING_VIDEO: 
            return {
                ...state,
                isLoading: action.payload
            }
        case UPLOAD_VIDEO: 
            return {
                ...state,
                ok: action.payload.ok,
                message: action.payload.message,
                errors: action.payload.errors,
                videos: action.payload.videos
            }
        case GET_VIDEOS:
            return {
                ...state,
                ok: action.payload.ok,
                message: action.payload.message,
                errors: action.payload.errors,
                videos: action.payload.videos
            }
        default:
            return state;
    }
}
