import React, { Component } from "react";
import history from "../../../history";
import { connect } from "react-redux";
import { CMSList } from "dg-cms";

// Styles
import "../../../styles/components/admin/list.scss";
import "../../../styles/components/admin/admin.scss";

// Components
import Sidebar from "../sidebar/sidebar.admin.component";

class List extends Component {
    render() {
        return (
            <div className="admin-container">
                <Sidebar />
                <main>
                    <div className="List-Container">
                        <CMSList
                            onEdit={(item) => {
                                history.push(`/admin/cms/${item._id}`);
                            }}
                        />
                    </div>
                </main>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(List);
