import React, { Component } from "react";
import "../../styles/components/blog/postcard.scss";
// AOS
import AOS from "aos";

class Postcard extends Component {
    constructor(props) {
        super(props);

        this.showPackage = this.showPackage.bind(this);
    }
    componentDidMount() {
        AOS.init();
    }

    showPackage() {
        window.location.pathname = `/blog/${this.props.item._id}`;
    }

    render() {
        const index = this.props.index;
        const item = this.props.item;
        const image = this.props.image;
        /*
        const parsedDate = new Date(Date.parse(this.props.item.createdAt));
        const date = `${parsedDate.getDate()}.${parsedDate.getMonth() + 1}.${
            parsedDate.getYear() - 100
        }`;
        */

        return (
            <li
                key={index}
                className="col-40 post-item"
                onClick={this.showPackage}
                data-aos="fade-in"
            >
                <div
                    className="photo-container"
                    style={{
                        backgroundImage: "url(" + image + ")",
                    }}
                ></div>

                <div className="post-title">
                    <h5> {item.title} </h5>
                    {/*
                    <div className="post-subtitle">
                        <h6 className="post-tag" data-aos="fade-right" data-aos-delay="400">{item.tag}</h6>
                        <h6 className="post-date" data-aos="fade-right" data-aos-delay="200">{date}</h6>
                        <h6 className="post-arrow">
                            <svg xmlns="http://www.w3.org/2000/svg" width="104.447" height="73.982" viewBox="0 0 104.447 73.982">
                                <g id="Group_145" data-name="Group 145" transform="translate(-822 -1953.519)">
                                    <line id="Line_6" data-name="Line 6" x2="88.606" transform="translate(822 1990.346)" fill="none" stroke="#fff" stroke-width="20"/>
                                    <line id="Line_8" data-name="Line 8" x2="36.375" y2="37.308" transform="translate(882.625 1960.5)" fill="none" stroke="#fff" stroke-width="20"/>
                                    <line id="Line_9" data-name="Line 9" x2="36.375" y2="37.308" transform="translate(919.466 1983.966) rotate(90)" fill="none" stroke="#fff" stroke-width="20"/>
                                </g>
                            </svg>
                        </h6>
                    </div>
                    */}
                </div>
            </li>
        );
    }
}

export default Postcard;
