import {
    BOOKING_SUBMIT,
    GET_BOOKINGS
} from "../actions/types";

const initialState = {
    bookings: [],
    currentBooking: {},
    message: "",
    ok: true,
    errors: [],
    isBookingLoading: false
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_BOOKINGS: 
            return {
                ...state,
                ok: action.payload.ok,
                message: action.payload.message,
                errors: action.payload.errors,
                bookings: action.payload.bookings
            }
        case BOOKING_SUBMIT:
            return {
                ...state,
                ok: action.payload.ok,
                message: action.payload.message,
                errors: action.payload.errors,
                currentBooking: action.payload.booking,
                isBookingLoading: false
            };
        default:
            return state;
    }
}
