import {
    UPLOAD_VIDEO,
    GET_VIDEOS,
    SET_IS_LOADING_VIDEO
} from './types';
import { getAccessToken } from './login.action';

export const uploadVideo = (data) => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
        let token = getAccessToken();
        
        dispatch({
            type: SET_IS_LOADING_VIDEO,
            payload: true
        });

        fetch(`${process.env.REACT_APP_API_URL}/api/v1/videos/create`, {
            method: "POST",
            headers: {
                access_token: token
            },
            body: data
        })
            .then(res => res.json())
            .then(post => {
                dispatch({
                    type: SET_IS_LOADING_VIDEO,
                    payload: false
                });

                dispatch({
                    type: UPLOAD_VIDEO,
                    payload: post
                });

                if (!post.ok) {
                    reject(post);
                } else {
                    resolve(post);
                }
            });
    });
};

export const getVideos = (page, page_size, page_name, section) => (dispatch, getState) => {
    let url = `${process.env.REACT_APP_API_URL}/api/v1/videos/?page=${page}&page_size=${page_size}`; 

    if (page_name && section) {
        url = `${process.env.REACT_APP_API_URL}/api/v1/videos/?page=${page}&page_size=${page_size}&page_name=${page_name}&section=${section}`;
    } else if (page_name) {
        url = `${process.env.REACT_APP_API_URL}/api/v1/videos/?page=${page}&page_size=${page_size}&page_name=${page_name}`;
    }
    return new Promise((resolve, reject) => {
        
        fetch(url, {
                method: "GET",
                headers: {
                    "content-type": "application/json"
                }
        })
        .then(res => res.json())
        .then(post => {
            dispatch({
                type: GET_VIDEOS,
                payload: post
            });

            if (!post.ok) {
                reject(post);
            } else {
                resolve(post);
            }
        });
    });
};
