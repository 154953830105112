import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { login } from '../../actions/login.action';
import LoginForm from './loginForm.component';
import history from '../../history';
import '../../styles/components/login.scss';

class Login extends Component {

    constructor(props) {
        super(props);

        this.onSubmit = this.onSubmit.bind(this);
    }

    onSubmit(form) {
        let projectName = `${process.env.REACT_APP_PROJECT_NAME}`;

        this.props.login(form)
        .then(res => {
            localStorage.setItem(`${projectName}::access_token`, res.access_token);
            history.push('/admin/posts');
        })
        .catch(res => {
            alert(res.message);
        });
    }

    render() {
        return (
            <div className="Login">
                <header>
                    <h1>The Reelists</h1>
                </header>
                <main>
                    <div className="container">
                        <h5>Login</h5>

                        <LoginForm onSubmit={this.onSubmit} />

                        <button type="button" className="btn-link">Forgot Password</button>
                    </div>
                </main>
            </div>
        );
    }
}

Login.propTypes = {
    login: PropTypes.func.isRequired
};

export default connect(null, { login })(Login);
