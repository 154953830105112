import React, { Component } from "react";
import TrackVisibility from "react-on-screen";
import { CMSSection } from "dg-cms";

// Styles
import '../../styles/components/services/production.service.scss';

// Components
import ServiceTemplate from "./services.template";
import Showreelredo from '../home/showreelredo.component';
import LogoBlock from './logo.block';
import VideoStack from '../ourwork/videosStack.component';
import Team from '../home/team.component';

// Assets
import { agencyVideos } from '../../assets/files/vimeolinks';
import client_logos from '../../assets/service_pages/Logos for Agency Page.png';
import { what_we_do, what_we_use } from '../../assets/files/agencies.service';

export default class Production extends Component {
    render() {
        const render_what_we_do = what_we_do.map((value, index) => (
            <div
                key={`agencies_what_we_do_${index}`}
                className="agencies-item"
            >
                <div className="icon">
                    <img src={value.icon} alt={value.name} />
                </div>
                <div className="text">
                    <p>{value.name}</p>
                </div>
            </div>
        ));

        const render_what_we_use = what_we_use.map((value, index) => (
            <div
                key={`agencies_what_we_use_${index}`}
                className="agencies-item"
            >
                <div className="icon">
                    <img src={value.icon} alt={value.name} />
                </div>
                <div className="text">
                    <p>{value.name}</p>
                </div>
            </div>
        ));

        return (
            <CMSSection name="Production Services Page">
                {({ cmsElmt }) => (
            <ServiceTemplate 
                title={`Agencies`}
                page={'Agencies'}
                section={'Header'}
                body={(
                    <React.Fragment>
                        <CMSSection name="Production Services">
                            {({ cmsElmt }) => (
                        <Showreelredo 
                            left={(
                                <div className="showreel-title"data-aos="fade-up">
                                    <h3>
                                            Services 
                                            <br />
                                            delivered on
                                            <br/>
                                            time, on 
                                            <br/>
                                            budget, and 
                                            <br/>
                                            above 
                                            <br />
                                            expectations.
                                        </h3>
                                </div>
                            )}
                            right={(
                                <div className="text-video">
                                    <div className="showreel-para-left">
                                        <h7>{cmsElmt("Paragraph", "text", "Whether your Agency is looking for a videographer to capture your activation, or you need a production company for your next TV spot, The Reelists have a video production solution for you. We're trusted by some of Ireland's leading Advertising, PR and Marketing Agencies to produce work for TV, Cinema, Online and Outdoor.")}</h7>
                                    </div>
                                    <div 
                                        className="showreel-vid"
                                    >
                                        <iframe title="The Reelists Showreel" src="https://player.vimeo.com/video/307170578?" width="640" height="360" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen /> 
                                    </div>
                                </div>
                            )}
                        />
                            )}
                                    </CMSSection>
                        <LogoBlock 
                            logo={client_logos}
                            logoAlt={`Agency client logos`}
                        />
                        <VideoStack 
                            videos={agencyVideos}
                            showSeeMore={false}
                        />
                        <TrackVisibility partialVisibility style={{width: "100%" }}>
                        {({ isVisible }) => <Team 
                            isVisible={isVisible} 
                            left={(
                                <div className="who-text-container">
                                    <h3 data-aos="fade-in">So what do we do?</h3>
                                    <div className="who-para-container" data-aos="fade-in">
                                        <h7>We’ve created Agency Case Studies, TV Ads, Social Campaigns, Same-Day On-Site Event Videos, Activation Videos and more. So whatever your video production needs, big or small, remember: We Do Everything.</h7>
                                        <div className="agencies-col-container">
                                            <div className="left">
                                                {render_what_we_do}
                                            </div>
                                            {/*
                                            <div className="right">
                                                {render_what_we_use}
                                            </div>
                                            */}
                                        </div>
                                    </div>
                            </div>
                            )}
                            page="Agencies"
                            section="So what do we do"
                        />}
                        </TrackVisibility>
                    </React.Fragment>
                )}
            />
                )}
            </CMSSection>
        );
    }
}
