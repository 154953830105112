import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { mobilenav } from "../../actions/mobilenav.action";
import { Spring } from "react-spring/renderprops";
import AOS from "aos";

// Styles
import "../../styles/components/sidedrawer/sidedrawer.scss";

// Assets
import logo from "../../assets/Web Assets/logo-small.svg";
import phone from "../../assets/Web Assets/footer/Telephone.svg";
import email from "../../assets/Web Assets/footer/Email.svg";
import house from "../../assets/Web Assets/footer/House.svg";
import instagram from "../../assets/Web Assets/social/instagram-1.svg";
import facebook from "../../assets/Web Assets/social/facebook-1.svg";
import vimeo from "../../assets/Web Assets/social/vimeo-1.svg";
import linkedin from "../../assets/Web Assets/social/linkedin-1.svg";
import twitter from "../../assets/Web Assets/social/twitter-1.svg";

// Actions
import { visible } from "../../actions/visible.action";
import { setVideoModal } from "../../actions/modal.action";

class Sidedrawer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showReelVideoId: "307170578",
            videoId: null,
        };

        this.setVideo = this.setVideo.bind(this);
    }

    componentDidMount() {
        AOS.init({
            delay: 300,
        });
    }

    setVideo() {
        // this.setState({ videoId: id });
        // this.props.visible();

        this.props.setVideoModal({
            showModal: true,
            url: this.state.showReelVideoId,
        });
    }

    navMenu = () => {
        return (
            <div className="container mobilenav">
                <Spring
                    from={{ opacity: 0.5, marginLeft: -200 }}
                    to={{ opacity: 1, marginLeft: 0 }}
                >
                    {(props) => (
                        <div style={props} className="side-menu">
                            <div
                                className="close"
                                onClick={() => this.props.mobilenav()}
                            >
                                <p>x</p>
                            </div>

                            <div
                                className="sidedrawer-logo"
                                data-aos="fade-down"
                            >
                                <img src={logo} alt="The Reelists Logo" />
                            </div>
                            <div className="col-70 desktop">
                                <div className="motto desktop">
                                    <h3 data-aos="fade-right">
                                        We Do Everything
                                    </h3>
                                </div>
                                <div className="bottom-section">
                                    <div
                                        className="contact-info"
                                        data-aos="fade-right"
                                    >
                                        {/*                    
                                        <div className="contact-line phoneno">
                                            <img src={phone} alt="phone" />
                                            <h6>01-2444185</h6>
                                        </div>
                                        */}
                                        <div className="contact-line emailaddr">
                                            <img src={email} alt="email" />
                                            <a href="mailto:info@thereelists.com">
                                                <h6>info@thereelists.com</h6>
                                            </a>
                                        </div>
                                        {/*                    
                                        <div className="contact-line address-line">
                                            <img src={house} alt="address" />
                                            <div className="address">
                                                <h6>
                                                    9 Baggot Street Upper,
                                                    <br />
                                                    Dublin 4
                                                </h6>
                                            </div>
                                        </div>
                                        */}
                                    </div>

                                    <div
                                        className="socials desktop"
                                        data-aos="fade-up"
                                        data-aos-anchor="top-center"
                                    >
                                        <a
                                            href="https://instagram.com/thereelistsfilm"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <img
                                                src={instagram}
                                                alt="instagram"
                                            />
                                        </a>
                                        <a
                                            href="https://www.facebook.com/thereelistsfilm"
                                            i
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <img
                                                src={facebook}
                                                alt="facebook"
                                            />
                                        </a>
                                        <a
                                            href="https://vimeo.com/thereelistsfilm"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <img src={vimeo} alt="vimeo" />
                                        </a>
                                        <a
                                            href="https://ie.linkedin.com/company/thereelists"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <img
                                                src={linkedin}
                                                alt="linkedin"
                                            />
                                        </a>
                                        <a
                                            href="https://twitter.com/TheReelistsFilm"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <img src={twitter} alt="twitter" />
                                        </a>
                                    </div>
                                    {/*
                                    <div
                                        className="showreel-containeri desktop"
                                        data-aos="fade-up"
                                    >
                                        <video width="480" autoPlay playsInline muted loop allowFullScreen="allow">
                                            <source
                                                src={sunset}
                                                type="video/mp4"
                                            />
                                            Your browser does not support the
                                            video tag
                                        </video>
                                    </div>
                                    */}
                                </div>
                            </div>

                            <div className="col-30">
                                <div className="menu" data-aos="fade-right">
                                    <NavLink
                                        to="/"
                                        onClick={() => {
                                            this.props.mobilenav();
                                            window.scrollTo(0, 0);
                                        }}
                                    >
                                        Home
                                    </NavLink>
                                    <NavLink
                                        to="/about-the-reelists"
                                        onClick={() => {
                                            this.props.mobilenav();
                                            window.scrollTo(0, 0);
                                        }}
                                    >
                                        About
                                    </NavLink>
                                    {/*
                                    <NavLink
                                        to="/who-we-are"
                                        onClick={() => {
                                            this.props.mobilenav();
                                            window.scrollTo(0, 0);
                                        }}
                                    >
                                        Meet the team
                                    </NavLink>
                                    */}
                                    <button
                                        type="button"
                                        onClick={() => this.setVideo()}
                                    >
                                        Showreel
                                    </button>
                                    <NavLink
                                        to="/our-work"
                                        onClick={() => {
                                            this.props.mobilenav();
                                            window.scrollTo(0, 0);
                                        }}
                                    >
                                        Our work
                                    </NavLink>
                                    <NavLink
                                        to="/our-services"
                                        onClick={() => {
                                            this.props.mobilenav();
                                            window.scrollTo(0, 0);
                                        }}
                                    >
                                        Our services
                                    </NavLink>
                                    {/*
                                    <NavLink
                                        to="/blog"
                                        onClick={() => {
                                            this.props.mobilenav();
                                            window.scrollTo(0, 0);
                                        }}
                                    >
                                        Blog
                                    </NavLink>
                                    */}
                                    <NavLink
                                        to="/contact-us"
                                        onClick={() => {
                                            this.props.mobilenav();
                                            window.scrollTo(0, 0);
                                        }}
                                    >
                                        Contact us
                                    </NavLink>
                                    <div className="mobile contact">
                                        <div className="contact-line phoneno">
                                            <img src={phone} alt="phone" />
                                            <a href="tel:012444185">
                                                <h6>01-2444185</h6>
                                            </a>
                                        </div>
                                        <div className="contact-line emailaddr">
                                            <img src={email} alt="email" />
                                            <a href="mailto:info@thereelists.com">
                                                <h6>info@thereelists.com</h6>
                                            </a>
                                        </div>
                                        <div className="contact-line address-line">
                                            <img src={house} alt="address" />
                                            <h6>
                                                {" "}
                                                3 Avenue Road, Portobello,
                                                Dublin 8
                                            </h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </Spring>
                <div
                    className="side-menu-mask"
                    onClick={() => this.props.mobilenav()}
                >
                    {" "}
                </div>
            </div>
        );
    };

    render() {
        return <div>{this.props.mobilenavState && this.navMenu()}</div>;
    }
}

const mapStateToProps = (state) => ({
    mobilenavState: state.mobilenav,
    visibility: state.visible,
});

export default connect(mapStateToProps, {
    mobilenav,
    visible,
    setVideoModal,
})(Sidedrawer);
