import React, { Component } from "react";
import { Link } from "react-router-dom";
import AOS from "aos";

// Styles
import "../../styles/components/button/buttonAlt.scss";

export default class ButtonAlt extends Component {
    constructor(props) {
        super(props);

        this.navigateTo = this.navigateTo.bind(this);
    }

    componentDidMount() {
        AOS.init();
    }

    navigateTo() {
        window.scrollTo(0,0);
    }

    render() {
        return (
            <div
                className={`button-alt`}
                data-aos={this.props.aosEffect}
                data-aos-delay={this.props.aosDelay}
            >
                <Link 
                    className="link"
                    to={this.props.link}
                    onClick={() => this.navigateTo()}
                > 
                    {this.props.img && (
                        <img src={this.props.img} alt="social" className="btn-img" />
                    )} 
                    <span className="un">
                        {this.props.text} &nbsp;
                    </span>
                    <h4 className="arrow">{`>`}</h4>
                </Link>
            </div>
        );
    }
}
