import React, { Component } from "react";
import TrackVisibility from "react-on-screen";
import { DgHelmet } from "dg-utils";

// Components
import Header from "../header/header.component.js";
import Footervideo from "../footervideo/footervideo.component.js";
import VimeoModal from '../modals/vimeoModal/vimeoModal';

import seojson from "../../assets/files/seojson.js";

export default class ServiceTemplate extends Component {

    render() {
        return (
            <div className={`services-template ${this.props.servicesClass ? this.props.servicesClass : ''}`}>
                <DgHelmet seojson={seojson}></DgHelmet>
                <TrackVisibility 
                    partialVisibility 
                    style={{width: "100%" }}
                >
                    {
                        ({ isVisible }) => (
                            <Header 
                                images={this.props.headerImages}
                                isVisible={isVisible} 
                                title={this.props.title}
                                page={this.props.page || 'Home'}
                                section={this.props.section || 'Header'}
                            />
                        )
                    }
                </TrackVisibility>
                
                {this.props.body ? this.props.body : ''}

                <TrackVisibility partialVisibility style={{width: "100%" }}>
                   {({ isVisible }) => (
                       <Footervideo 
                           images={this.props.footerImages}
                        isVisible={isVisible} 
                        page={this.props.page || 'Home'}
                        section={this.props.section || 'Header'}
                    />
                    )}
                </TrackVisibility>

                {/* Modals */}
                <VimeoModal />
            </div>
        );
    }
}
