import React, { Component } from 'react';
import { connect } from 'react-redux';
import Modal from 'react-modal';

// Assets

// Styles
import '../../../styles/components/modals/videoModal.scss';

// Actions
import { setVideoModal } from '../../../actions/modal.action';

// Components

class VimeoModal extends Component {

    render() {

        return (
            <Modal 
                isOpen={this.props.modals.isVideoModalShowing}
                onRequestClose={() => this.props.setVideoModal({
                    showModal: false, 
                    url: ''
                })}
                contentLabel="Vimeo Video"
                style={{
                    overlay: {
                        zIndex: '1000000',
                        backgroundColor: 'rgba(0,0,0,0.4)'
                    },
                    content: {
                        position: 'relative',
                        top: '0',
                        left: '0',
                        right: '0',
                        bottom: '0',
                        height: 'unset',
                        width: 'unset',
                        margin: 'unset',
                        padding: 'unset',
                        borderRadius: 'unset',
                        border: 'unset',
                        background: 'transparent',
                        flex: '0 1 auto'
                    }
                }}
            >
                <div className="Video-Modal">
                    <div className="modal-close-container">
                        <button
                            className="modal-close"
                            onClick={() => this.props.setVideoModal({
                                showModal: false, 
                                url: ''
                            })}
                        >
                            &times;
                        </button>
                    </div>
                    <iframe 
                        title="The Reelists"
                        className="video-iframe" 
                        src={`https://player.vimeo.com/video/${this.props.modals.url}?autoplay=1&loop=1&color=6AD2D4&title=0&byline=0&portrait=0`}  
                        frameBorder="0" 
                        allow="autoplay; fullscreen" 
                        allowFullScreen
                    />
                </div>
            </Modal>
        );
    }
}

const mapStateToProps = state => ({
    modals: state.modals
});

export default connect(mapStateToProps, { 
    setVideoModal
})(VimeoModal);
