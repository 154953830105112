import React, { Component } from "react";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import history from "../../../history";

// Styles
import "../../../styles/components/admin/list.scss";
import "../../../styles/components/admin/admin.scss";

// Actions
import { getContacts } from "../../../actions/contact.action";

// Components
import Sidebar from "../sidebar/sidebar.admin.component";

class ContactList extends Component {

    componentDidMount() {
        this.props.getContacts()
        .catch((res) => {
            Swal.fire(`Error`, res.message, "error");
        });
    }

    render() {
        let rows = [];

        if (
            this.props.contacts &&
            this.props.contacts.contacts &&
            this.props.contacts.contacts.length > 0
        ) {
            console.log()
            rows = this.props.contacts.contacts.map((value, index) => (
                <tr 
                    key={`admin_contact_${index}`}
                    onClick={() => history.push(`/admin/contact/${value._id}`)}
                >
                    <td>{value.your_details.name}</td>
                    <td>{value.your_details.email}</td>
                    <td>{value.your_details.subject}</td>
                    <td>{value.your_details.message}</td>
                    <td>
                        {`${new Date(value.createdAt).toLocaleDateString()} ${new Date(value.createdAt).toLocaleTimeString()}`}
                    </td>
                </tr>
            ));
        } else {
            rows = [
                <tr>
                    <td colSpan="5">No Contacts available ...</td>
                </tr>,
            ];
        }

        return (
            <div className="admin-container">
                <Sidebar />
                <main>
                    <div className="List-Container">
                        <div className="list">
                            <table>
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Email</th>
                                        <th>Subject</th>
                                        <th>Message</th>
                                        <th>Created On</th>
                                    </tr>
                                </thead>
                                <tbody>{rows}</tbody>
                            </table>
                        </div>
                    </div>
                </main>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    contacts: state.contact,
});

export default connect(mapStateToProps, {
    getContacts,
})(ContactList);
